<template>
  <Navbar />

  <div class="flex flex-col">
    <ul class="steps steps-vertical md:steps-horizontal mt-5 mb-5">
      <li class="step step-primary">Sign Up</li>
      <li class="step">Connect Riot Account</li>
      <li class="step">Let's Go</li>
    </ul>

    <p class="text-secondary mt-5 text-lg">
      Thank you for signing up. We have sent you an account activation email. <br>
      <span class="font-bold text-info">Please check your spam folder as well</span> <br>
      In case you face any errors, please feel free to contact us.
    </p>
  </div>

</template>

<script>
import Navbar from "@/components/Navbar";
import {eventLog, sessionInit} from "@/log_api";
export default {
  name: 'AccountActivateInform',
  components: {Navbar},
  setup() {
    // session start
    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Account Activate Inform',
          'page_details': {}
        },
        window.location.href
    )
  }
}
</script>