<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},

    }" v-if="!isHidden"/>
    <div class="hidden md:flex mt-4 border-r border-gray-900 rounded-box flex-none justify-center">
      <ul class="menu">
        <li class="text-gray-500" v-for="(tab, ind) in tabs">
          <a class="capitalize" :class="{'tab-active': currentTab === ind}"
             @click="getClick('prev'); currentTab = ind; getClick('now'); sendTabInfo()">{{ tab }}</a>
        </li>
      </ul>
    </div>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading"></FullScreenLoader>
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap w-full" style="height: 92vh">
        <div class="flex md:hidden mb-4 flex-none justify-center">
          <div class="tab">
            <a class="tab tab-lifted uppercase" v-for="(tab, ind) in tabs" :class="{'tab-active': currentTab === ind}"
               @click="getClick('prev'); currentTab = ind; getClick('now'); sendTabInfo()">{{ tab }}</a>
          </div>
        </div>
        <div class="bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box" v-show="currentTab === 0">
          <div class="px-3 py-3">
            <p class="text-lg leading-10 text-secondary uppercase mb-5 border-b border-gray-900">Performance
              Averages</p>
            <div class="flex flex-wrap flex-col md:flex-row justify-between mb-2">
              <div class="shadow stats mb-3 md:mb-0" v-for="(v, k) in getOverviewData">
                <div class="bg-base-100 border border-base-300 shadow shadow-md rounded-box px-6 py-2 hover:bg-gray-900">
                  <div class="stat-title">{{ k }}</div>
                  <div class="stat-value text-2xl text-secondary text-xl tracking-wide">{{ v }}</div>
                </div>
              </div>
            </div>
            <LineChart
                :chart-data="impactChart"
                :options="impactChartOption"
                :height='250'
                class="px-4 py-4"></LineChart>
            <div class="flex">
              <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 border border-base-300 shadow-md">
                <div class="card-body">
                  <h2 class="opacity-50 mb-4">
                    Most Bought
                  </h2>
                  <img :src="weaponImg(getWeaponData.mostUsed)" class="mt-3 w-3/5">
                  <p class="text-lg px-2 py-2 mt-5 text-secondary font-black leading-loose tracking-wide">{{ getWeaponData.mostUsedCount }}</p>
                </div>
              </div>
              <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 border border-base-300 shadow-md">
                <div class="card-body">
                  <h2 class="opacity-50 mb-4">Least Bought</h2>
                  <img :src="weaponImg(getWeaponData.leastUsed)" class="mt-3 w-3/5">
                  <p class="text-lg px-2 py-2 mt-5 text-secondary font-black leading-loose tracking-wide">{{ getWeaponData.leastUsedCount }}</p>
                </div>
              </div>
              <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 border border-base-300 shadow-md">
                <div class="card-body">
                  <h2 class="opacity-50 mb-4">Most Effective</h2>
                  <img :src="weaponImg(getWeaponData.mostKill)" class="w-3/5 mt-3 ">
                  <p class="text-lg px-2 py-2 mt-5 text-secondary font-black leading-loose tracking-wide">{{ getWeaponData.mostKillCount }}</p>
                </div>
              </div>
              <div class="card bg-base-100 mx-2 my-2 lg:card-side w-2/5 border border-base-300 shadow-md">
                <div class="card-body">
                  <h2 class="opacity-50 mb-4">Least Effective</h2>
                  <img :src="weaponImg(getWeaponData.leastKill)" class="mt-3 w-3/5">
                  <p class="text-lg px-2 py-2 mt-5 text-secondary font-black leading-loose tracking-wide">{{ getWeaponData.leastKillCount }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box mt-5" v-show="currentTab === 1">
          <keep-alive>
            <MapwiseKD
                :chart-type="getKillData.chartType"
                :point-data="getKillData.pointData"
                :mapname="getKillData.mapname"
                :is-premium="isPremium"
                @fueltest="fuelEmit"
            ></MapwiseKD>
          </keep-alive>
          <p class="text-xs text-gray-700 text-center mb-4">Defender = Green and Attacker = Red</p>
        </div>
        <div class="bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box mt-5" v-show="currentTab === 2">
          <keep-alive>
            <MapwiseKD
                :chart-type="getDeathData.chartType"
                :point-data="getDeathData.pointData"
                :mapname="getDeathData.mapname"
                :is-premium="isPremium"
                @fueltest="fuelEmit"
            ></MapwiseKD>
          </keep-alive>
           <p class="text-xs text-gray-700 text-center mb-4">Defender = Green and Attacker = Red</p>
        </div>
        <div class="flex space-x-4 mt-5" v-show="currentTab === 3">
          <div class="flex-none bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box w-3/5">
            <DoughnutChart
                :chart-data="agentChart"
                class="px-8 py-8" :width='200' :height='100'></DoughnutChart>
            <div class="flex items-center justify-center space-x-2 py-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="flex-none h-5 w-5" viewBox="0 0 20 20"
                   fill="rgba(255,255,255,0.5)">
                <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"/>
              </svg>
              <p class="text-sm text-warning">
                Percentage of your kills in the selected map for each agent
              </p>
            </div>
          </div>
          <div
              class="flex flex-col bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box items-center justify-center w-full">
            <img :src="agentImg(dataDump['mostkillagent'])" class="border-6 border-secondary">
            <p class="text-xl py-5 px-5 badge badge-info badge-lg mt-5">Most Kills</p>
          </div>
        </div>
        <div class="bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box mt-5" v-show="currentTab === 4">
          <LineChart
              :chart-data="attackerChart"
              :options="distributionChartOption"
              class="px-4 py-4"></LineChart>
          <div class="flex space-x-4 mt-2 mb-3 px-8 py-4 items-center">
            <div class="tooltip tooltip-secondary" data-tip="Analysis">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" viewBox="0 0 20 20" fill="#e94560">
                <path fill-rule="evenodd"
                      d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <div class="divider divider-vertical"></div>
            <p class="text-gray-400">
              On the attacker side, you secured most kills {{ timeConv(dataDump['max_attack_kill_x_val']) }} into the
              rounds
              whereas you died the most around {{ timeConv(dataDump['max_attack_death_x_val']) }} into the round
            </p>
          </div>
        </div>
        <div class="bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box mt-5" v-show="currentTab === 5">
          <LineChart
              :chart-data="defenderChart"
              :options="distributionChartOption"
              class="px-4 py-4"></LineChart>
          <div class="flex space-x-4 mt-2 mb-3 px-8 py-4 items-center">
            <div class="tooltip tooltip-secondary" data-tip="Analysis">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" viewBox="0 0 20 20" fill="#e94560">
                <path fill-rule="evenodd"
                      d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <div class="divider divider-vertical"></div>
            <p class="text-gray-400">
              As a defender, you secured most kills {{ timeConv(dataDump['max_defend_kill_x_val']) }} into the rounds
              whereas you died the most around {{ timeConv(dataDump['max_defend_death_x_val']) }} into the round
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l border-gray-900 artboard hidden md:block">
      <div class="bg-base-100 ml-4 artboard border border-base-100 shadow-md rounded-box px-4 py-2 items-center">
        <p class="text-xl leading-loose tracking-wider text-secondary uppercase border-b border-gray-900 mb-4">
          Filters</p>
        <select class="select border border-gray-900 mx-2" name="maps" ref="mapFilter">
          <option>Ascent</option>
          <option>Bind</option>
          <option>Haven</option>
          <option>Breeze</option>
          <option>Icebox</option>
          <option>Split</option>
          <option>Fracture</option>
        </select>

        <div v-if="isPremium">
          <select class="bg-base-100 mt-5 mx-2 px-3 py-5 border border-gray-900 rounded-box" name="agents" multiple
                  v-model="agentFilter">
            <option>Astra</option>
            <option>Breach</option>
            <option>Brimstone</option>
            <option>Chamber</option>
            <option>Cypher</option>
            <option>Jett</option>
            <option>KAY/O</option>
            <option>Killjoy</option>
            <option>Omen</option>
            <option>Phoenix</option>
            <option>Raze</option>
            <option>Reyna</option>
            <option>Sage</option>
            <option>Skye</option>
            <option>Sova</option>
            <option>Viper</option>
            <option>Yoru</option>

          </select>

          <label class="label text-sm font-light mx-2 mt-5">Start Date</label>
          <input ref="startDate" type="date" class="bg-base-200 mx-2 w-4/5 rounded-box px-3 py-2 mt-1 mb-4 input">
        </div>
        <div v-else>
          <div>
            <div class="alert alert-warning text-xs mt-2">
              You get Map filter and analysis for your latest 6 matches for free
            </div>
            <div class="flex items-center mt-2 bg-base-200 bg-opacity-20 border border-gray-900 px-2 py-2 rounded-box space-x-6"
                 title="Swift Fuel to try premium for free!">
              <router-link to="/refuel">
                <svg xmlns="http://www.w3.org/2000/svg"
                     class="flex-none h-8 w-8 mr-3 cursor-pointer rounded-box"
                     viewBox="0 0 20 20" fill="#e94560">
                  <path fill-rule="evenodd"
                        d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                        clip-rule="evenodd"/>
                </svg>
              </router-link>
              {{ tokens }}
              <div class="tooltip tooltip-primary"
                   data-tip="Swift Fuel is a token system to help you try premium features for free.It is not time bound, so you can try these features whenever you like">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-none h-5 w-5 cursor-pointer" viewBox="0 0 20 20"
                     fill="rgba(255,255,255,0.5)">
                  <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"/>
                </svg>
              </div>
            </div>
            <button v-if="!isPremium" class="btn btn-warning mt-2 ml-2" @click="buyThis()">Fuel Up</button>
          </div>
          <select disabled class="bg-base-100 mt-5 mx-2 px-3 py-5 border border-gray-900 rounded-box" name="agents"
                  multiple
                  v-model="agentFilter">
            <option>Astra</option>
            <option>Breach</option>
            <option>Brimstone</option>
            <option>Chamber</option>
            <option>Cypher</option>
            <option>Jett</option>
            <option>KAY/O</option>
            <option>Killjoy</option>
            <option>Neon</option>
            <option>Omen</option>
            <option>Phoenix</option>
            <option>Raze</option>
            <option>Reyna</option>
            <option>Sage</option>
            <option>Skye</option>
            <option>Sova</option>
            <option>Viper</option>
            <option>Yoru</option>

          </select>

          <label class="label text-sm font-light mx-2 mt-5">Start Date</label>
          <input disabled ref="startDate" type="date"
                 class="bg-base-200 mx-2 w-4/5 rounded-box px-3 py-2 mt-1 mb-4 input">
        </div>
        <p class="text-sm mx-1 px-2 py-2"><span class="font-bold">{{ getTotal.totalMatches }}</span> Matches Available
        </p>
        <p v-if="!isPremium" class="text-sm mx-1 px-2 py-2 mb-2">
          <router-link to="/pricing" class="text-secondary">Upgrade to Premium</router-link>
          to view all <span class="font-bold">{{ getTotal.allMatchesLen }}</span> Matches
        </p>
        <button class="btn btn-outline mx-2" @click="filteredData">Apply</button>
        <button class="btn btn-outline" @click="resetData">Reset</button>
        <div class="bg-base-100 mt-6 rounded-box px-3 py-2">
          <a class="btn bg-blue-600 mb-3 hover:bg-blue-800" href="https://discord.gg/bYwaNhZezT" target="_blank">
            <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mr-2" viewBox="0 0 16 16"> <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" fill="white"></path> </svg>
            Join Discord
          </a>
          <button v-if="!isPremium" class="btn btn-secondary" @click="$router.push('/pricing')">
            <i class="uil uil-rocket text-xl mr-2"></i> Go Pro
          </button>
        </div>
        <div class="alert alert-error mt-5 mx-2" v-if="getIsAvailable.isAvailable === 'no' || sneakybeaky">
          <label v-if="sneakybeaky">Thats a sneaky move. Contact Us on discord. We might have something for you</label>
          <label v-else>No data found for the selection</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {computed, onMounted, onUpdated, ref} from "vue";
import MapwiseKD from "@/components/MapwiseKD";
import store from "@/store";
import Sidebar from "@/components/Sidebar";
import {DoughnutChart, LineChart} from "vue-chart-3";
import FullScreenLoader from "@/components/FullScreenLoader";
import {useRouter} from "vue-router";
import {sessionInit, eventLog} from "@/log_api";
import {useToast} from "vue-toastification";

export default {
  name: 'Mapwise',
  components: {
    FullScreenLoader,
    MapwiseKD,
    Sidebar,
    DoughnutChart,
    LineChart,
  },
  setup() {
    let csrf_token = '';
    const isLoading = ref(false);
    const dataDump = ref({});
    const isPremium = ref(false);
    const isHidden = ref(false);

    const sneakybeaky = ref(false);
    const tokens = ref(0);

    // Filter Vars
    const mapFilter = ref('Ascent');
    const agentFilter = ref([]);
    const startDate = ref('');

    const currentTab = ref(0);
    const beforeTab = ref(0);
    const nowTab = ref(0);
    const tabs = ref(['Overview', 'Killmap', 'Deathmap', 'Agents', 'Attacker', 'Defender'])
    const router = useRouter();

    const toast = useToast();

    // const cmap = ['red', 'green', 'yellow', 'blue', 'purple']

    async function initial() {
      isLoading.value = true;
      await axios.get('/valorant/csrf-token/')
          .then(response => {
            csrf_token = response.data
          }).catch(
              error => {
                if (error.response.status === 401) {
                  router.push('/login')
                }
              }
          )
      await axios.post(
          '/valorant/career-filtered/v1/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'puuid': store.state.user.uuid,
            'mapname': mapFilter.value.value,
            'dateFilter': startDate.value.value,
            'agentFilter': agentFilter.value
          }
      ).then(
          response => {
            dataDump.value = response.data
            isLoading.value = false;
          }
      )
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true;
            }
          }
      )
      await axios.get(
          '/account/in/coin-update/'
      ).then(
          response => {
            tokens.value = response.data
          }
      )
    }

    async function buyThis() {
      let csrf_token = ''
      await axios.get('/valorant/csrf-token/')
          .then(response => {
            csrf_token = response.data
          }).catch(
              error => {
                if (error.response.status === 401) {
                  router.push('/login')
                }
              }
          )
      await axios.patch(
          '/account/in/coin-update/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            }
          }
      ).then(
          response => {
            if (response.data['status'] === 'Success') {
              isPremium.value = true;
              tokens.value = response.data['data'];
              toast.success('Successfully fuelled', {
                timeout: 3000,
              })
            } else {
              tokens.value = response.data['data'];
              toast.error('Insufficient Fuel', {
                timeout: 5000,
              })
            }
          }
      )
    }

    function filteredData() {
      if (!isPremium.value) {
        if (agentFilter.value.length !== 0 || startDate.value['value'] !== '') {
          sneakybeaky.value = true;
        }
      }
      if (sneakybeaky.value !== true) {
        initial();
      }

      // event trigger
      eventLog(
          'button_click',
          {
            'page_name': 'Mapwise',
            'button_name': 'Filter'
          },
          window.location.href
      )
    }

    function resetData() {
      mapFilter.value.value = 'Ascent';
      agentFilter.value = [];
      startDate.value.value = '';
      initial();
    }

    // Utility

    function getClick(type) {
      if (type === 'prev') {
        beforeTab.value = currentTab.value;
      } else {
        nowTab.value = currentTab.value;
      }
    }

    function sendTabInfo() {
      // event trigger
      eventLog(
          'tab_navigation',
          {
            'page_name': 'Mapwise',
            'tab_name': tabs.value[nowTab.value],
            'prev_tab': tabs.value[beforeTab.value]
          },
          window.location.href
      )
    }

    function agentImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val}.png`
    }

    function weaponImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}.png`
    }

    function timeConv(value) {
      const newvalue = value;
      let valString;
      let newv;
      if (newvalue < 60) {
        if (newvalue < 10) {
          newv = `0${newvalue}`;
        } else {
          newv = newvalue;
        }
        valString = `${newv} seconds`;
      } else if ((60 <= newvalue) && (newvalue < 120)) {
        const tsplit = newvalue - 60;
        if (tsplit < 10) {
          newv = `0${tsplit}`;
        } else {
          newv = tsplit;
        }
        valString = `1 minute ${newv} seconds`;
      } else if ((120 <= newvalue) && (newvalue <= 180)) {
        const tsplit = newvalue - 120;
        if (tsplit < 10) {
          newv = `0${tsplit}`;
        } else {
          newv = tsplit;
        }
        valString = `2 minutes ${newv} seconds`;
      }
      return valString;
    }

    function fuelEmit() {
      buyThis();
    }


    onMounted(() => {
      initial();
      //session innit
      sessionInit();
      // event trigger
      eventLog(
          'page_view',
          {
            'page_name': 'Mapwise',
            'page_details': {
              'premium': isPremium.value
            }
          },
          window.location.href
      )
    });


    const getKillData = computed(() => {
      return {
        chartType: 'kill',
        pointData: dataDump.value.kills_location,
        mapname: dataDump.value.mapname,
      }
    });

    const getDeathData = computed(() => {
      return {
        chartType: 'death',
        pointData: dataDump.value.deaths_location,
        mapname: dataDump.value.mapname,
      }
    });

    const impactChart = computed(() => {
      return {
        labels: dataDump.value.kdmatchwisex,
        datasets: [
          {
            type: 'line',
            label: 'KD',
            fill: true,
            data: dataDump.value.kdmatchwisey,
            yAxisID: 'y1',
            backgroundColor: [
              'rgba(17, 24, 39, 0.6)',
            ],
            borderColor: [
              'rgba(17, 24, 39, 1)',
            ],
            borderWidth: 2,
            lineTension: 0.35,
            pointStyle: 'rect',
            pointRadius: 3,
            pointHoverRadius: 8,
          },
          {
            type: 'bar',
            label: 'Damage',
            fill: false,
            data: dataDump.value.dmgmatchwisey,
            yAxisID: 'y2',
            backgroundColor: [
              'rgba(233, 69, 96, 0.2)',
            ],
            borderColor: [
              'rgba(233, 69, 96, 1)',
            ],
            borderWidth: 2,
            borderRadius: 3,
            borderSkipped: false,
            barThickness: 2.5,
          }
        ]
      }
    });

    const impactChartOption = computed(() => {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        scales: {
          y1: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: 'KD'
            },
            position: 'right',
            grid: {
              display: true,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            title: {
              display: true,
              text: 'Damage'
            },
            position: 'left',
            grid: {
              display: true,
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
          xAxes: {
            reverse: true,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
            drawBorder: false,
            title: {
              display: true,
              text: 'Matches',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          },
        },
      }
    });

    const attackerChart = computed(() => {
      return {
        labels: dataDump.value.x_vals,
        datasets: [
          {
            type: 'line',
            label: 'Kills',
            fill: true,
            data: dataDump.value.attacker_kills,
            pointRadius: 0,
            backgroundColor: [
              'rgba(70, 234, 208, 0.2)',
            ],
            borderColor: [
              '#46EAD0',
            ],
            borderWidth: 2,
          },
          {
            type: 'line',
            label: 'Deaths',
            fill: true,
            data: dataDump.value.attacker_deaths,
            pointRadius: 0,
            backgroundColor: [
              'rgba(255, 0, 0, 0.2)',
            ],
            borderColor: [
              '#e94560',
            ],
            borderWidth: 2,
          },
        ]
      }
    });

    const defenderChart = computed(() => {
      return {
        labels: dataDump.value.x_vals,
        datasets: [
          {
            type: 'line',
            label: 'Kills',
            fill: true,
            data: dataDump.value.defender_kills,
            pointRadius: 0,
            backgroundColor: [
              'rgba(70, 234, 208, 0.2)',
            ],
            borderColor: [
              '#46EAD0',
            ],
            borderWidth: 2,
          },
          {
            type: 'line',
            label: 'Deaths',
            fill: true,
            data: dataDump.value.defender_deaths,
            pointRadius: 0,
            backgroundColor: [
              'rgba(255, 0, 0, 0.2)',
            ],
            borderColor: [
              '#e94560',
            ],
            borderWidth: 2,
          }
        ]
      }
    });

    const distributionChartOption = computed(() => {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        plugins: {
          tooltip: {
            enabled: false
          }
        },
        scales: {
          y: {
            ticks: {
              display: false
            }
          },
          x: {
            grid: {
              display: true,
            },
            ticks: {
              callback: function (value, index, values) {
                const newvalue = Number(this.getLabelForValue(value).toFixed(0));
                let valString;
                let newv;
                if (newvalue < 60) {
                  if (newvalue < 10) {
                    newv = `0${newvalue}`;
                  } else {
                    newv = newvalue;
                  }
                  valString = `0:${newv}`;
                } else if ((60 <= newvalue) && (newvalue < 120)) {
                  const tsplit = newvalue - 60;
                  if (tsplit < 10) {
                    newv = `0${tsplit}`;
                  } else {
                    newv = tsplit;
                  }
                  valString = `1:${newv}`;
                } else if ((120 <= newvalue) && (newvalue <= 180)) {
                  const tsplit = newvalue - 120;
                  if (tsplit < 10) {
                    newv = `0${tsplit}`;
                  } else {
                    newv = tsplit;
                  }
                  valString = `2:${newv}`;
                }
                return valString;
              }
            },
            drawBorder: false,
            title: {
              display: true,
              text: 'Time Elapsed (Time into Round)',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          }
        },
      }
    });

    const getOverviewData = computed(() => {
      return {
        Kills: dataDump.value['kills'],
        Deaths: dataDump.value['deaths'],
        Headshot: `${dataDump.value['hs']}%`,
        Aces: dataDump.value['aces'],
        QuadKills: dataDump.value['quadkills'],
        Trifecta: dataDump.value['trifecta'],
      }
    });

    const agentChart = computed(() => {
      return {
        labels: dataDump.value['agents'],
        datasets: [
          {
            label: 'Pick Percentage',
            data: dataDump.value['pickpct'],
            backgroundColor: [
              '#EF4444',
              '#C27171',
              '#DB8000',
              '#804A00',
              '#88A300',
              '#DBFF29',
              '#86FF29',
              '#429900',
              '#00FF44',
              '#009E2A',
              '#008C9E',
              '#00E1FF',
              '#0015FF',
              '#5400A3',
              '#BF00FF',
              '#FF00A6',
              '#FF0040',
              '#5E0D0D'
            ],
          }
        ]
      }
    });

    const getWeaponData = computed(() => {
      return {
        mostUsed: dataDump.value['mostusedweapon'],
        leastUsed: dataDump.value['leastusedweapon'],
        mostKill: dataDump.value['mostkillweapon'],
        leastKill: dataDump.value['leastkillweapon'],
        mostUsedCount: dataDump.value['mostusedweaponcount'],
        leastUsedCount: dataDump.value['leastusedweaponcount'],
        mostKillCount: dataDump.value['mostkillweaponcount'],
        leastKillCount: dataDump.value['leastkillweaponcount']
      }
    });

    const getIsAvailable = computed(() => {
      return {
        isAvailable: dataDump.value['isavailable']
      }
    });

    const getTotal = computed(() => {
      return {
        totalMatches: dataDump.value['totalmatches'],
        allMatchesLen: dataDump.value['allmatcheslen']
      }
    });

    return {
      getIsAvailable,
      getTotal,
      dataDump,
      getKillData,
      getDeathData,
      impactChart,
      impactChartOption,
      attackerChart,
      defenderChart,
      distributionChartOption,
      getOverviewData,
      agentChart,
      getWeaponData,
      mapFilter,
      agentFilter,
      startDate,
      filteredData,
      resetData,
      tabs,
      currentTab,
      agentImg,
      weaponImg,
      isLoading,
      isPremium,
      sneakybeaky,
      isHidden,
      getClick,
      sendTabInfo,
      buyThis,
      tokens,
      timeConv,
      fuelEmit,
    }
  }
}
</script>