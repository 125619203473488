<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading"/>
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <div class="px-3 py-3 bg-base-200 bg-opacity-20 border border-base-300 shadow-md rounded-box">
          <div class="flex flex-col justify-between items-center">
            <div class="rounded-box"><img class="mask mask-squircle"
                                          :src="agentImg(dataDump.mostkillagent)"></div>
            <div>
              <div><p class="text-2xl text-center uppercase mt-6 text-secondary">{{ username }}</p>
                <p class="text-center mt-4"><span
                    class="text-secondary uppercase font-black mt-4 px-4 py-4">{{ getOverviewData.PlayTime }} hours played</span>
                </p></div>
              <div class="md:flex md:flex-row md:space-x-2 mt-4">
                <div class="stat rounded-box border border-primary">
                  <div class="stat-title">Favourite Weapon</div>
                  <div class="stat-value">{{ getWeaponData.mostKill }}</div>
                  <div class="stat-desc">{{ getWeaponData.mostKillCount }}</div>
                </div>
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Aces</div>
                  <div class="stat-value">{{ getOverviewData.Aces }}</div>
                </div>
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Quad Kills</div>
                  <div class="stat-value">{{ getOverviewData.QuadKills }}</div>
                </div>
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Trifecta</div>
                  <div class="stat-value">{{ getOverviewData.Trifecta }}</div>
                </div>
              </div>
              <div class="md:flex md:flex-row md:space-x-2 mt-4">
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Kills</div>
                  <div class="stat-value">{{ getOverviewData.TotalKills }}</div>
                  <div class="stat-desc">Avg {{ getOverviewData.Kills }}</div>
                </div>
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Deaths</div>
                  <div class="stat-value">{{ getOverviewData.TotalDeaths }}</div>
                  <div class="stat-desc">Avg {{ getOverviewData.Deaths }}</div>
                </div>
                <div class="stat rounded-box border border-gray-900">
                  <div class="stat-title">Assists</div>
                  <div class="stat-value">{{ getOverviewData.TotalAssists }}</div>
                  <div class="stat-desc">Avg {{ getOverviewData.Assists }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l border-gray-900 artboard hidden md:block">
    </div>
  </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import store from "@/store";
import FullScreenLoader from "@/components/FullScreenLoader";
import {useRouter} from "vue-router";
import {sessionInit, eventLog} from "@/log_api";

export default {
  name: 'ProfileCard',
  components: {
    FullScreenLoader,
    Sidebar
  },
  setup() {
    const username = localStorage.getItem('username');
    const isLoading = ref(false);
    let csrf_token = '';
    const isHidden = ref(false);
    const router = useRouter();
    const dataDump = ref({});

    async function initial() {
      isLoading.value = true;
      await axios.get('/valorant/csrf-token/')
          .then(response => {
            csrf_token = response.data
          }).catch(
              error => {
                if (error.response.status === 401) {
                  router.push('/login')
                }
              }
          )

      await axios.post(
          '/valorant/profile-card/v1/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'puuid': store.state.user.uuid
          }
      )
          .then(response => {
            dataDump.value = response.data
            isLoading.value = false;
          })
    }

    // Utility

    function agentImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val}.png`
    }

    function weaponImg(val) {
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}.png`
    }


    onMounted(() => {
      initial();

      // session innit
      sessionInit();

      // event trigger
      eventLog(
          'page_view',
          {
            'page_name': 'Profile card',
            'page_details': {}
          },
          window.location.href
      )
    });

    const getOverviewData = computed(() => {
      return {
        PlayTime: dataDump.value['allplaytime'],
        Kills: dataDump.value['kills'],
        Deaths: dataDump.value['deaths'],
        Assists: dataDump.value['assists'],
        Score: dataDump.value['score'],
        TotalKills: dataDump.value['totalkills'],
        TotalDeaths: dataDump.value['totaldeaths'],
        TotalAssists: dataDump.value['totalassists'],
        TotalScore: dataDump.value['totalscore'],
        Headshot: `${dataDump.value['hs']}%`,
        Aces: dataDump.value['aces'],
        QuadKills: dataDump.value['quadkills'],
        Trifecta: dataDump.value['trifecta'],
      }
    });

    const getWeaponData = computed(() => {
      return {
        mostUsed: dataDump.value['mostusedweapon'],
        leastUsed: dataDump.value['leastusedweapon'],
        mostKill: dataDump.value['mostkillweapon'],
        leastKill: dataDump.value['leastkillweapon'],
        mostUsedCount: dataDump.value['mostusedweaponcount'],
        leastUsedCount: dataDump.value['leastusedweaponcount'],
        mostKillCount: dataDump.value['mostkillweaponcount'],
        leastKillCount: dataDump.value['leastkillweaponcount']
      }
    });

    return {
      dataDump,
      getOverviewData,
      getWeaponData,
      agentImg,
      weaponImg,
      username,
      isLoading,
      isHidden
    }
  }
}
</script>