<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <div class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div class="flex flex-col justify-betweem items-center">
            <p class="text-secondary text-2xl uppercase leading-loose tracking-wide">{{ username }}'s Account</p>
            <div class="stat rounded-box mt-5">
              <div v-if="isPremium">
                <div class="stat-title">Active Plan</div>
                <div class="stat-value mb-4">Premium</div>
                <button class="btn btn-warning" @click="cancelSub">Cancel Subscription</button>
              </div>
              <div v-else>
                <div class="stat-title">Active Plan</div>
                <div class="stat-value">Free</div>
              </div>
            </div>
            <div class="stat rounded-box mt-2">
              <div class="stat-title">Your Referral Invite: {{ uCount }} Invited</div>
              <div class="stat-title">https://swiftskill.gg/user/invite/{{ uName }}</div>
            </div>
            <div class="stat rounded-box mt-3">
              <div>
                <div class="stat-title">
                  Swift Fuel Pitstop
                </div>
                <div class="mt-4 mb-1 text-info text-sm">
                  Invite your friends and ping us on Discord to get 10,000 Swift Fuel for both
                </div>
                <div class="stat-value mt-2">
                  <a href="https://discord.gg/bYwaNhZezT" class="btn btn-warning">Join Our Discord</a>
                </div>
                <div class="border border-b border-base-300 mt-3"></div>
                <div class="mt-4 mb-1 text-info text-sm">
                  Connect your Discord Account for 5,000 Swift Fuel
                </div>
                <div v-if="!$store.state.user.discord" class="stat-value">
                  <button @click="discordRedirect" class="btn btn-info">Link my Discord</button>
                </div>
                <div v-else class="stat-value">
                  Discord Account Connected
                </div>
              </div>
            </div>
            <div v-if="isPremium" class="stat rounded-box mt-5">
              <div v-if="isAffiliate">
                <div class="stat-title">Affiliate Status</div>
                <div class="stat-value">Active</div>
                <table class="table mt-10 mb-10">
                  <thead>
                  <tr>
                    <th>Free</th>
                    <th>Monthly</th>
                    <th>Quarterly</th>
                    <th>Yearly</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{ accDump.free_users }}</td>
                    <td>{{ accDump.monthly_subs }}</td>
                    <td>{{ accDump.quarterly_subs }}</td>
                    <td>{{ accDump.yearly_subs }}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="flex flex-col items-start">
                  <div>
                    Your Referral Code is <span class="text-xl uppercase text-info ml-1">{{ accDump.refCode }}</span>
                  </div>
                  <div>
                    Your Referral Link is: <span
                      class="text-info">https://swiftskill.gg/signup?ref={{ accDump.refCode }} </span>
                    <button @click="copyLink" class="ml-2 btn btn-info">
                      <div class="mr-4">Copy Link</div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                      </svg>
                    </button>
                  </div>
                </div>

              </div>
              <div class="flex flex-col space-y-4" v-else>
                <div class="stat-title">Affiliate Status</div>
                <div class="stat-value">Inactive</div>
                <button class="btn btn-primary" @click="affiliateReq" v-if="!affiliatePending">Join</button>
                <button class="btn disabled" disabled v-else>Request Pending</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {onBeforeUnmount, onMounted, ref} from "vue";
import Sidebar from "@/components/Sidebar";
import {useRouter} from "vue-router";
import {sessionInit, eventLog} from "@/log_api";

export default {
  name: 'AccountSettings',
  components: {
    Sidebar
  },
  setup() {
    let csrf_token;
    const accDump = ref({});
    const username = localStorage.getItem('username');
    const uName = ref('');
    const uCount = ref(0);
    const isPremium = ref(false);
    const isAffiliate = ref(false);
    const affiliatePending = ref(false);
    const isHidden = ref(false);
    const router = useRouter();

    async function initial() {
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )

      await axios.post(
          '/account/in/account-gate/',
          {
            headers: {'X-CSRFToken': csrf_token}
          }
      ).then(
          response => {
            if (response.data !== 'Non-Premium') {
              accDump.value = response.data;
              isAffiliate.value = true;
            }
          }
      )

      await axios.get(
          '/account/in/user-signup-referral/'
      ).then(
          response => {
            uName.value = response.data['name'];
            uCount.value = response.data['count'];
          }
      )

      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true
            }
          }
      )
    }

    async function affiliateReq() {
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )

      await axios.post(
          '/account/in/affiliate-gate/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            }
          }
      ).then(
          response => {
            if (response.data === 'Requested') {
              affiliatePending.value = true
            } else {
              alert('Some error occurred during processing your request. Please contact us if the issue persists. It could be due to your previous pending requests')
            }
          }
      )
      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Account Settings',
            'button_name': 'Affiliate Request'
          },
          window.location.href
      )
    }

    function copyLink() {
      let copytext = `https://swiftskill.gg/signup?ref=${accDump.value.refCode}`;
      //console.log(copytext)
      navigator.clipboard.writeText(copytext)

      alert('Copied link to clipboard')
    }

    async function cancelSub() {
      await axios.get(
          '/account/in/stripesub-cancel-end'
      ).then(
          response => {
            if (response.data === 'Subscription Cancelled') {
              alert('We are sorry you intend to discontinue your premium status. One of our team member would get in touch to ensure the process is smooth.')
            }
          }
      )
    }

    function discordRedirect(){
      window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=943876911791231007&redirect_uri=https%3A%2F%2Fswiftskill.gg%2Fdiscord-success&response_type=code&scope=identify%20email%20connections'
    }

    onMounted(() => {
      initial()
      // event trigger
      eventLog(
          'page_view',
          {
            'page_name': 'Account Settings',
            'page_details': {
              'premium': isPremium.value,
              'affiliate': isAffiliate.value
            }
          },
          window.location.href
      )
    })

    // on created
    sessionInit()

    return {
      username,
      accDump,
      isPremium,
      isAffiliate,
      affiliateReq,
      affiliatePending,
      copyLink,
      cancelSub,
      isHidden,
      discordRedirect,
      uName,
      uCount,
    }
  }
}
</script>