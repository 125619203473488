<template>

</template>

<script>

export default {
  name: 'PurchaseFail',
}

</script>