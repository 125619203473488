<template>
  <Navbar/>

  <div class="flex flex-col mt-4 px-4 py-4 rounded-box items-center justify-center mb-12">
    <p class="text-4xl text-secondary font-bold">
      SwiftSkill Refuel
    </p>
    <p class="font-light mt-2">
      Not sure whether to subscribe to SwiftSkill Premium? Try Premium features by fuelling them with your Swift Fuel
    </p>
    <p class="font-light mt-5">
      Out of fuel? Several ways to refuel. What are those?
    </p>

    <div class="flex flex-row items-center justify-center space-x-2 mt-10 mb-8">
      <div class="bg-base-200 w-64 rounded-box px-4 py-4 my-2">
        <p class="text-2xl text-center">5,000 Fuel</p>
        <div class="divider">@</div>
        <p class="text-lg text-center capitalize mb-8">£1.49</p>
        <button class="btn btn-outline w-full" @click="subscribe(1)">Refuel</button>
      </div>
      <div class="bg-secondary w-64 rounded-box px-4 py-4 my-2 h-72">
        <p class="text-2xl text-center font-bold leading-loose tracking-wide">50,000 Fuel</p>
        <div class="divider">@</div>
        <p class="text-2xl text-center font-bold leading-loose tracking-wide capitalize">
          £4.99
        </p>
        <button class="btn bg-gray-900 w-full mt-8" @click="subscribe(12)">Refuel</button>
      </div>
      <div class="bg-base-200 w-64 rounded-box px-4 py-4 my-2">
        <p class="text-2xl text-center">10,000 Fuel</p>
        <div class="divider">@</div>
        <p class="text-lg text-center capitalize mb-6">£2.99</p>
        <button class="btn btn-outline w-full" @click="subscribe(3)">Refuel</button>
      </div>
    </div>
    <p class="font-light">Have questions? <a href="https://discord.gg/bYwaNhZezT" class="text-info">Join our Discord </a></p>
  </div>
  <Footer />
</template>

<script>

import axios from "axios";
import {eventLog} from "@/log_api";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: 'PurchaseCoin',
  components: {Footer, Navbar},
  setup() {
    let session_url;
    let session_id;
    let csrf_token;

    const priceMap = {
      1: 'price_1KSP3oFuoGzcfUuYbS9fNKYb',
      3: 'price_1KSP3oFuoGzcfUuYrOMjT9Nu',
      12: 'price_1KSP3oFuoGzcfUuY3AHzUqHl',
    }

    async function subscribe(interval) {
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )
      await axios.post(
          '/account/in/purchase-coin/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'priceid': priceMap[interval],
          }
      ).then(
          response => {
            session_url = response.data.url
            session_id = response.data.id
          }
      ).catch(
          error => {
            console.log(error);
            axios.get('/account/in/failed')
          }
      )
      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Pricing',
            'button_name': `Subscribe ${interval}`
          },
          window.location.href
      )

      setTimeout(window.location.href = session_url, 5000)
    }

    return {
      subscribe
    }
  }
}
</script>