<template>
  <Navbar/>

  <div v-if="!oldUser" class="flex px-4 mt-14 mb-28 py-4 justify-center items-center space-y-6">
    <div class="flex flex-col border border-gray-900 px-6 py-3 rounded-box">
      <p class="text-lg">SwiftSkill <span class="text-secondary">for Teams</span></p>
      <p class="text-xs text-gray-600 mb-3 mt-2">You get</p>
      <ul class="list-decimal ml-6 text-secondary py-2">
        <li class="mb-2">
          In-depth Team Analysis <br>
          <span class="text-gray-400 text-xs">Get extensive information about how you played as a team</span>
        </li>
        <li class="mb-2">
          Coach Mode <br>
          <span
              class="text-gray-400 text-xs">Coaches get granular information to keep players' performance on track</span>
        </li>
        <li class="mb-2">
          Extensive Heatmaps <br>
          <span class="text-gray-400 text-xs">Get team heatmaps with granular filters</span>
        </li>
        <li class="mb-2">
          Scrim Management <br>
          <span class="text-gray-400 text-xs">Plan your scrims and track your performance accordingly</span>
        </li>
        <li class="mb-2">
          Player Management <br>
          <span class="text-gray-400 text-xs">Manage your players and track their wellbeing too</span>
        </li>
      </ul>
      <p class="mb-2">
        and much more... <br>
        <span class="text-gray-400 text-xs">Interested? Connect with us on Discord to talk more</span>
      </p>
      <a class="btn bg-blue-600 mt-2 mb-3 hover:bg-blue-800" href="https://discord.gg/bYwaNhZezT" target="_blank">
        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             class="mr-2" viewBox="0 0 16 16">
          <path
              d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"
              fill="white"></path>
        </svg>
        To know more Join our Discord
      </a>
    </div>
    <form class="form-control border-l border-gray-900 px-3 ml-2" @submit.prevent>
      <label for="referral" class="label">How did you hear about us?</label>
      <select id="referral" ref="referralMedium" class="select select-secondary mb-3">
        <option>Search</option>
        <option>YouTube</option>
        <option>Reddit</option>
        <option>Twitter</option>
        <option>Instagram</option>
        <option>Twitch</option>
        <option>Referred by Friends</option>
        <option>Other</option>
      </select>
      <input class="input bg-base-200 mb-2" type="email" placeholder="Your Email Please" v-model="email">
      <button class="btn btn-secondary" @click="emailUpdate">Let's get to Analysis</button>
    </form>
  </div>
  <div v-else style="height: 75vh">
    <FullScreenLoader/>
  </div>

  <Footer/>
</template>

<script>

import {useRoute, useRouter} from "vue-router";
import axios from "axios";
import {useStore} from "vuex";
import {ref} from "vue";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import FullScreenLoader from "@/components/FullScreenLoader";

export default {
  name: 'RiotRedirectLogin',
  components: {FullScreenLoader, Footer, Navbar},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const email = ref('');
    const referralMedium = ref('');
    let code = route.query.code;
    if (route.query.error) {
      alert('Authentication Error')
      router.push('/login')
    }
    let refresh_code = 'None';
    const oldUser = ref(true);

    async function initial() {
      if (localStorage.getItem('refreshToken')) {
        refresh_code = localStorage.getItem('refreshToken')
      }
      await axios.post(
          '/account/in/riot-signup/',
          {
            code: code,
            refresh: refresh_code
          }
      ).then(
          response => {
            if (response.status === 200) {
              let id_token = response.data.id_token
              if (id_token !== 'None') {
                document.cookie = `idToken=; Max-Age=-99999999; path=/`
                let d = new Date();
                d.setTime(d.getTime() + 60 * 60 * 1000);
                let expires = "expires=" + d.toUTCString();
                document.cookie = `idToken=${id_token}; ${expires};path=/`
              }
              localStorage.setItem('accessToken', response.data.access)
              localStorage.setItem('refreshToken', response.data.refresh)

              // redirect to dashboard
              if (response.data['newuser'] === true) {
                oldUser.value = false;
                localStorage.setItem('task_id', response.data['taskid']);
                localStorage.setItem('fetchingComplete', 'false');
                localStorage.setItem('skh', 'true');
              } else {
                router.push('/dashboard')
              }

              let token = response.data.token
              store.commit('setToken', token)

              axios.defaults.headers.common["Authorization"] = "Token " + token
              localStorage.setItem('token', token)
            }
          }
      ).catch(
          error => {
            console.log(error)
          }
      )

      await axios
          .get("/account/in/me/")
          .then(response => {
            store.commit('setUser', {
              'username': response.data.username,
              'id': response.data.id,
              'uuid': response.data.uuid
            })
            localStorage.setItem('username', response.data.username)
            localStorage.setItem('userid', response.data.id)
            localStorage.setItem('uuid', response.data.uuid)
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })

    }

    async function emailUpdate() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )
      await axios.patch(
          '/account/in/riot-signup/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            email: email.value,
            referral_medium: referralMedium.value.value
          }
      ).then(
          response => {
            if (response.data === 'Updated') {
              router.push('/dashboard')
            }
          }
      )
    }

    initial()

    return {
      email,
      referralMedium,
      oldUser,
      emailUpdate
    }
  }
}

</script>