<template>
  <div class="home">
    <Navbar/>

    <div class="hero h-screen">
      <div class="flex flex-col md:text-center hero-content">
        <div class="flex items-center">
          <div class="flex flex-col items-center justify-end">
            <h1 class="mb-24 mt-20 text-4xl text-center md:text-8xl leading-10 font-black uppercase">
              In-depth analytics<br> <span class="text-primary">no cap.</span>
            </h1>
            <div class="flex flex-col md:flex-row ml-4 items-center justify-center images__logos">
              <img class="w-48 mr-4"
                   src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/game_titles/V_Lockup_Horizontal_Pos_Red.png">
              <div class="flex flex-col md:flex-row image__ComingSoon px-4 md:border-l md:border-gray-900">
                <img class="w-48"
                     src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/game_titles/TFT_Logotype_Horiz_White.png">
                <img class="w-48"
                     src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/game_titles/lol-logo-rendered-hi-res.png">
                <img class="w-48"
                     src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/game_titles/legends-of-runeterra-logo.png">
                <img class="w-36"
                     src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/game_titles/Halo-Infinite-Logo-PNG-HD-Quality.png">
              </div>

            </div>
          </div>
        </div>
        <div class="md:space-x-6 mb-3 mt-12">
          <router-link @click="getClicks('Continue To Dashboard')" v-if="$store.state.isAuthenticated" to="/dashboard"
                       class="btn btn-primary">Continue to
            Dashboard
          </router-link>
          <router-link @click="getClicks('Sign Up')" v-else to="/signup" class="btn btn-primary">Start for Free
          </router-link>
          <a href="#more-info" class="btn btn-outline">What is this about?</a>
        </div>
        <div class="animate-bounce mt-10 mb-10">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="hero flex flex-col h-screen items-center mt-96 md:mt-0" id="more-info">
      <div class="flex flex-col mb-4 mt-48 items-center justify-between mt-4">
        <div class="px-4">
          <p>While we are building SwiftSkill for your game, why not register for early access?</p>
          <form class="form-control mt-4" @submit.prevent>
            <label for="game__list" class="text-xs mb-2">Select your game</label>
            <select ref="gameTag" id="game__list" class="select select-primary">
              <option></option>
              <option>League of Legends</option>
              <option>Legends of Runeterra</option>
              <option>Halo Infinite</option>
              <option>Teamfight Tactics</option>
              <option>PUBG</option>
              <option>Other</option>
            </select>
            <input type="email" class="input input-primary mt-2" v-model="emailRoutine" placeholder="Your Email" required>
            <button class="btn btn-outline w-36 mt-6" @click="notifyRoutine">Notify Me</button>
          </form>
        </div>
        <div class="md:flex mt-8 py-4 space-x-6 w-full items-center justify-center hidden">
          <div class="w-5/12 px-4">
            <p class="text-4xl mb-2 text-center text-primary">Teams</p>
            <p>We work with professional esports teams and organizations to develop custom solutions for teams</p>
            <p class="mt-4"><a class="text-primary" href="mailto:support@swiftskill.gg">Connect with us</a> to know more how we can help your team!</p>
          </div>
          <div class="w-5/12 px-4 border-l border-gray-900">
            <p class="text-4xl mb-2 text-center text-primary">Academia</p>
            <p>We believe academia to be the entry point for esports. We work with various academic institutions to help their students ace their performance</p>
            <p class="mt-4"><a class="text-primary" href="mailto:support@swiftskill.gg">Connect with us</a> to know more how we work and enhance students' performance!</p>
          </div>
        </div>
      </div>
      <div class="space-x-6 mt-20 mb-12">
        <router-link v-if="!$store.state.isAuthenticated" @click="getClicks('Sign Up')" to="/signup"
                     class="btn btn-primary">Start for Free
        </router-link>
        <router-link v-else @click="getClicks('Dashboard')" to="/dashboard" class="btn btn-primary">Continue to
          Dashboard
        </router-link>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<style scoped>

.l-img {
  border: #171618 solid 1px;
  border-radius: 50px;
  filter: drop-shadow(0 0 5px #3C167A);
}
</style>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from "@/components/Footer";
import {sessionInit, eventLog} from "@/log_api";
import axios from "axios";
import {ref} from "vue";

export default {
  name: 'Home',
  components: {
    Footer,
    Navbar
  },
  setup() {
    const emailRoutine = ref('');
    const gameTag = ref('');

    function getClicks(button_name) {
      //event trigger
      eventLog(
          'button_click',
          {
            'page_name': 'Home Page',
            'button_name': button_name
          },
          window.location.href
      )
    }

    async function notifyRoutine() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )
      console.log(emailRoutine.value)
      await axios.post(
          '/account/in/routine-notify/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'email': emailRoutine.value,
            'tag': gameTag.value.value
          }
      ).then(
          response => {
            if (response.data === 'Success') {
              emailRoutine.value = '';
              gameTag.value.value = '';
              console.log('Successful')
            }
          }
      )
    }

    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Home Page',
          'page_details': {}
        },
        window.location.href
    )

    return {
      getClicks,
      emailRoutine,
      gameTag,
      notifyRoutine
    }
  }
}
</script>
