<template>
  <Navbar/>
  <div>
    <h1 class="text-2xl text-secondary text-center font-bold leading-loose tracking-wide">Choose your plan</h1>
    <p class="leading-10 tracking-wide text-lg text-center text-gray-600">
      If you don't find SwiftSkill a fit for you, don't worry.
      You can cancel anytime.
    </p>
    <p class="leading-10 tracking-wide text-lg text-center text-purple-400">
      Compare features and if you have any questions, drop us a note on Discord.
    </p>
  </div>

  <div class="flex flex-row items-center justify-center space-x-2 mt-10 mb-8">
    <div class="bg-base-200 bg-opacity-20 border border-gray-900 w-64 rounded-box px-4 py-4 my-2">
      <p class="text-2xl text-center">Monthly</p>
      <div class="divider">@</div>
      <p class="text-lg text-center capitalize mb-8">£4.99 billed monthly</p>
      <button class="btn btn-outline w-full" @click="subscribe(1)">Buy</button>
    </div>
    <div class="bg-secondary w-64 rounded-box px-4 py-4 my-2">
      <p class="text-2xl text-center font-bold leading-loose tracking-wide">Annually</p>
      <div class="divider">@</div>
      <p class="text-2xl text-center font-bold leading-loose tracking-wide capitalize">
        £49.99
        <br> <span class="text-xl text-white">billed
        yearly</span>
      </p>
      <p class="text-sm text-center mb-2">£4.16/month</p>
      <button class="btn bg-gray-900 border-none w-full" @click="subscribe(12)">Buy</button>
    </div>
    <div class="bg-base-200 bg-opacity-20 border border-gray-900 w-64 rounded-box px-4 py-4 my-2">
      <p class="text-2xl text-center">Quarterly</p>
      <div class="divider">@</div>
      <p class="text-lg text-center capitalize mb-3">£12.99 billed quarterly</p>
      <p class="text-sm text-center mb-3">£4.33/month</p>
      <button class="btn btn-outline w-full" @click="subscribe(3)">Buy</button>
    </div>
  </div>

  <div class="flex flex-col items-center mt-6 form-control">
    <label class="label">
      <span class="label-text">Have a coupon code?</span>
    </label>
    <div class="relative">
      <input type="text" v-model="coupon" placeholder="Enter Code" class="w-full pr-16 input input-primary input-bordered">
      <button class="absolute top-0 right-0 rounded-l-none btn btn-primary" @click="couponCheck">Apply</button>
    </div>
    <div ref="codeCheck"></div>
  </div>

  <div class="flex flex-col justify-center mt-5 mb-8">
    <table class="table table-zebra mb-5">
      <thead>
      <tr>
        <th>Features</th>
        <th>Free</th>
        <th>Premium</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>No BS</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Career Stats</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Round by Round Analysis</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Interactive Kill Heatmaps</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Interactive Death Heatmaps</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Unlimited Matches</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Detailed Filters</td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Historical Analysis</td>
        <td>
          Latest 6 Matches
        </td>
        <td>
          All Available Matches
        </td>
      </tr>
      <tr>
        <td>Personalized Discord Bots <span class="badge badge-info">Coming Soon</span> </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Team Analysis <span class="badge badge-info">Coming Soon</span> </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Customizable Dashboard <span class="badge badge-info">Coming Soon</span> </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      <tr>
        <td>Personalized Training Routine <span class="badge badge-info">Coming Soon</span> </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
        <td>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
          </svg>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <Footer />
</template>

<script>

import Navbar from "@/components/Navbar";
import axios from "axios";
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Footer from "@/components/Footer";
import {sessionInit, eventLog} from "@/log_api";

export default {
  name: 'Pricing',
  components: {Footer, Navbar},
  setup() {
    let csrf_token;
    let session_url;
    let session_id;
    let isPremium = ref('');
    const coupon = ref('');
    const codeCheck = ref('');
    const router = useRouter();
    const priceMap = {
      1: 'price_1KG0cJFuoGzcfUuYfcEQcWnT',
      3: 'price_1KG0ceFuoGzcfUuYa2pA1Ujk',
      12: 'price_1KG0cuFuoGzcfUuYabFrBu6X',
    }

    onMounted(async () => {
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            isPremium.value = response.data
          }
      );

      // session innit
      await sessionInit();

      // event trigger
      await eventLog(
          'page_view',
          {
            'page_name': 'Pricing',
            'page_details': {}
          },
          window.location.href
      )
    })

    async function couponCheck() {
      await axios.get('/valorant/csrf-token/')
          .then(response => {
            csrf_token = response.data;
          })

      await axios.post(
          '/account/in/coupon-gate/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'coupon_code': coupon.value
          }
      ).then(
          response => {
            if (response.data === 'Code Matched'){
              codeCheck.value.textContent = 'Coupon Applied';
              codeCheck.value.classList = "alert alert-success mt-4"
            } else {
              codeCheck.value.textContent = 'Invalid Coupon';
              codeCheck.value.classList = "alert alert-error mt-4"
              coupon.value = ''
            }
          }
      )

      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Pricing',
            'button_name': 'Apply Coupon'
          },
          window.location.href
      )
    }

    async function subscribe(interval) {
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )
      await axios.post(
          '/account/in/stripesub/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'priceid': priceMap[interval],
            'coupon_code': coupon.value
          }
      ).then(
          response => {
            session_url = response.data.url
            session_id = response.data.id
          }
      ).catch(
          error => {
            console.log(error);
            axios.get('/account/in/failed')
          }
      )
      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Pricing',
            'button_name': `Subscribe ${interval}`
          },
          window.location.href
      )

      setTimeout(window.location.href=session_url, 5000)
    }

    return {
      coupon,
      codeCheck,
      subscribe,
      isPremium,
      couponCheck,
    }
  }
}
</script>
