<template>
  <Navbar/>

  <div class="flex flex-col justify-center space-y-8 space-x-2 mt-10 mb-8">
    <h5 class="font-bold text-center leading-10 tracking-wide">Affiliate Program Terms of Service</h5>
    <p>
      Below you’ll find the Affiliate Program Terms of Service. By signing up as an affiliate with the SwiftSkill
      Limited (and https://swiftskill.gg), you agree to the following Terms of Service.


      This agreement is by and between SwiftSkill Limited and/or their assigns and all affiliates. Unless
      the context requires otherwise, SwiftSkill Limited and/or their assigns shall be referred to as ‘us,
      we, or our’ and you shall be referred to as ‘you, your or affiliate.’

      You understand that SwiftSkill Limited and/or their assigns does not guarantee or predict any type of
      profit or response from said services. Affiliate agrees to hold SwiftSkill Limited harmless from and
      against any and all losses, claims, expenses, suits, damages, costs, demands or liabilities, joint or
      several, of whatever kind or nature that SwiftSkill Limited and/or their assigns may become subject
      arising out of or relating in any way to the use of the services provided under this agreement, including,
      without limitation, in each case attorneys’ fees, costs and expenses actually incurred in defending against
      or enforcing any such losses, claims, expenses, suits, damages or liabilities.
    </p>
    <ul class="space-y-8 list-disc list-outside">
      <li>
        Services to be provided. We agree to pay you certain commissions as described on our website for referral sales
        made by customers. You agree to accept payment via your nominated PayPal account.
      </li>
      <li>
        Termination. We may terminate your account:
        <ol>
          <li>if you violate our Terms Of Service Policy;</li>
          <li>if you promote The Stretching Institute in a manner that is unethical or inappropriate; or</li>
          <li>for any reason, in our sole discretion.</li>
        </ol>
      </li>
      <li>
        No Warranties. WE MAKE NO WARRANTIES TO YOU OF ANY KIND, EXPRESSED OR IMPLIED, WITH RESPECT TO THE SERVICE THE
        STRETCHING INSTITUTE, AND/OR THEIR ASSIGNS PROVIDE YOU. WE EXPRESSLY DISCLAIM ANY IMPLIED WARRANTY OF MERCHANT
        ABILITY OR FITNESS OF THIS SERVICE FOR A PARTICULAR PURPOSE. We shall not be liable for any damages suffered by
        you, whether indirect, special, incidental, exemplary, or consequential, including, but not limited to, loss of
        data or service interruptions, regardless of cause or fault. We are not responsible for your lost profits or for
        your loss of data or information.
      </li>
      <li>
        TERMS. You agree:
        <ol>
          <li>to use our system in a manner that is ethical and in conformity with community standards;</li>
          <li>to respect the privacy of other users (you shall not intentionally seek data or passwords belonging to
            other users, nor will you modify files or represent yourself as another user unless explicitly authorized to
            do so by that user);
          </li>
          <li>to respect the legal protection provided by copyright law, trade secret law, or other laws protecting
            intellectual property; and
          </li>
          <li>to accept commercial emails from us.</li>
        </ol>
        If we learn of a violation or likely violation of our TERMS OF SERVICE, we will attempt to notify you. If you do
        not take immediate remedial action that is satisfactory to us, or in the event of a serious violation of the
        TERMS OF SERVICE, we reserve the right to terminate your account immediately. Every effort will be made to
        inform you prior to account termination, and to re-establish your account upon receiving such representations
        from you as we deem appropriate in the circumstances.

        <br>
        <b>SPAM</b>
        <p>
          It is against your affiliate agreement to advertise our website, our products, or any other website promoting
          our products, via unsolicited email (SPAM). DO NOT send unsolicited email to promote us or our products, or
          you will be in violation of your affiliate agreement and your account will be terminated, forfeiting all
          outstanding referral fees. YOUR SERVICE WILL BE TERMINATED IMMEDIATELY AND WITHOUT WARNING SHOULD YOU USE OUR
          SYSTEM AS PART OF ANY BULK EMAIL CAMPAIGN. You may also be subject to fines and legal actions as a result of
          your bulk email promotions.
        </p>
        <br>
        <b>PAY PER CLICK</b>
        <p>
          It is against your affiliate agreement to advertise our website or our products via PAY PER CLICK (PPC) or PAY
          PER IMPRESSION (CPI, CPM) advertising networks; including Google AdWords, Yahoo Search Marketing, MSN
          AdCenter, MIVA, SearchFeed, 7Search or any other PPC, CPI or CPM advertising network. DO NOT promote us, or
          our products, on any PPC, CPI or CPM advertising network, or you will be in violation of your affiliate
          agreement and your account will be terminated, forfeiting all outstanding referral fees. You may also be
          subject to fines and legal actions as a result of your PPC, CPI or CPM advertising promotions.
        </p>
      </li>
      <li>
        Assignment. This agreement is personal to you. You may not assign your rights under this agreement without our
        prior written consent. If you do assign your rights, as would be the case were someone other than you were to
        use your account, you shall remain liable to us for any fees due under this agreement. We may assign this
        agreement at any time.
      </li>
      <li>
        Notification of Account Changes. You agree to provide us with such other information relating to your use of
        this service as we deem necessary or desirable. You agree to notify us if your address, email address, telephone
        number, billing information, and any other information associate with your affiliate account changes
      </li>
      <li>
        Change of Terms and Conditions. We reserve the right to change the terms and conditions of this agreement as
        needed. Use of our servers by you after said changes constitutes acceptance of those new terms and conditions.
      </li>
      <li>
        Notices. All notices, requests, demands, and other communications under this agreement shall be in writing and
        shall be deemed to have been given on the date of delivery: if delivered personally to the party to whom notice
        is to be given; if sent by electronic mail with a cc: to sender; if sent by fax; or on the third day after
        mailing by first class mail.
      </li>
      <li>
        General Provisions.
        <ol>
          <li>
            The subject headings of the articles and sections are for convenience only, and shall not affect the
            construction or interpretation of any of its provisions.
          </li>
          <li>
            If any portion of this agreement is found invalid or unenforceable, that portion shall be severed and the
            remainder of this agreement shall remain in force.
          </li>
          <li>
            This agreement constitutes the entire agreement between us pertaining to its subject matter and supersedes
            all prior agreements, representations, and understandings.
          </li>
          <li>
            In the event of a dispute, the parties agree to submit the matter to the Community Dispute Resolution
            Service or any recognized Arbitration Board located within our state and county, before instituting
            litigation.
          </li>
        </ol>
      </li>
    </ul>
  </div>
  <Footer />
</template>

<script>

import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import {eventLog, sessionInit} from "@/log_api";

export default {
  name: 'AffiliateTOS',
  components: {Footer, Navbar},
  setup() {
    // session start
    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Affiliate TOS',
          'page_details': {}
        },
        window.location.href
    )
  }
}
</script>