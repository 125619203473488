<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading"></FullScreenLoader>
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <div class="px-6 py-3 box bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div class="flex flex-col items-center justify-center rounded-box p-2">
            <p class="text-secondary text-5xl font-black mb-6">Pro Routines & Guides</p>
            <p class="text-center">Get early access to routines, tricks, and insider knowledge by your fav Pros</p>
            <div class="ribbon ribbon-top-right">
              <span>Coming Soon</span>
            </div>
          </div>
          <div class="flex items-center justify-center mt-4">
            <form @submit.prevent><input v-model="emailRoutine" type="email" class="input border-gray-900"
                         placeholder="Enter your Email to get invite">
              <button @click="notifyRoutine" class="btn bg-base-100 border-secondary ml-4">Let's Go!</button>
            </form>
          </div>
        </div>
        <div class="flex mt-3 flex-none justify-center mb-3">
          <div class="tabs">
            <a class="tab tab-bordered uppercase" v-for="(tab, ind) in tabs" :class="{'tab-active': currentTab === ind}"
               @click="getClick('prev'); currentTab = ind; getClick('now'); sendTabInfo()">{{ tab }}</a>
          </div>
        </div>
        <div v-show="currentTab === 0" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 1" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 2" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 3" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 4" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 5" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab === 6" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <div>
            <ul class="flex flex-col" v-for="(routine, index) in showRoutine(currentTab)">
              <li class="flex space-x-12">
                <span class="flex items-center" :class="spaceMap(index + 1)">
                  <i class="uil uil-angle-double-down text-3xl text-secondary" v-for="itr in index"></i>
                </span>
                <span class="text-xl font-semibold tracking-wide leading-10">{{ index + 1 }}</span>
                <ul class="py-2 mb-2">
                  <li v-for="rText in routine" class="mb-1 border-l-4 " :class="classMap(rText)">
                    <span class="capitalize ml-2">
                      {{ rText }}
                    </span>
                  </li>
                </ul>
                <span class="flex items-center">
                  <i class="uil uil-angle-double-up text-3xl text-green-400" v-for="itr in index + 1"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg artboard hidden md:block">
      <div class="ml-2 text-sm px-6 py-3 text-gray-500 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box mt-12">
          <div class="flex flex-wrap flex-col md:flex-row justify-between">
            <p class="ml-2">
              Your total average matches are used to structure your gaming routine, with 4 competitive/unrated games at max.
              <br> Each number corresponds to a session.
              <br> Breaks are usually for refreshing yourself and keeping yourself hydrated
              <br> Stretching helps your body react better.
            </p>
            <div class="flex flex-col mt-2">
              <span class="flex items-center">
                <i class="uil uil-angle-double-down text-3xl text-secondary"></i> Corresponds to energy levels (higher it is, lower is your energy)
              </span>
              <span class="flex items-center">
                <i class="uil uil-angle-double-up text-3xl text-green-400"></i> Corresponds to sharpness (higher it is, better you play)
              </span>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import Sidebar from "@/components/Sidebar";
import {BarChart} from 'vue-chart-3';
import {ref, onMounted} from "vue";
import axios from "axios";
import store from "@/store";
import {useRouter} from "vue-router";
import FullScreenLoader from "@/components/FullScreenLoader";
import {eventLog} from "@/log_api";
import {useToast} from "vue-toastification";

export default {
  name: 'TrainingRoutine',
  components: {
    Sidebar,
    BarChart,
    FullScreenLoader
  },
  setup() {
    const isHidden = ref(false);
    const isLoading = ref(false);
    const isPremium = ref(false);
    const router = useRouter();

    const currentTab = ref(0);
    const beforeTab = ref(0);
    const nowTab = ref(0);
    const tabs = ref([]);

    let dataDump = ref({});

    const weekAvg = ref({});
    const routine = ref({});

    const toast = useToast();

    const emailRoutine = ref('');

    async function initial() {
      let csrf_token = '';
      isLoading.value = true;
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true
            }
          }
      )
      await axios.get(
          '/valorant/csrf-token/'
      ).then(response => {
            csrf_token = response.data
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )
      await axios.post(
          '/valorant/training/v1/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'puuid': store.state.user.uuid
          }
      ).then(response => {
            dataDump.value = response.data;
            tabs.value = dataDump.value['weekdays'];
            currentTab.value = dataDump.value['curr_day'];
            weekAvg.value = dataDump.value['week_avg'];
            routine.value = dataDump.value['routine'];
            isLoading.value = false;
          }
      ).catch(error => {
            console.log(JSON.stringify(error))
          }
      )
    }

    function getClick(type) {
      if (type === 'prev') {
        beforeTab.value = currentTab.value;
      } else {
        nowTab.value = currentTab.value;
      }
    }

    function sendTabInfo() {
      // event trigger
      eventLog(
          'tab_navigation',
          {
            'page_name': 'Monthly',
            'tab_name': tabs.value[nowTab.value],
            'prev_tab': tabs.value[beforeTab.value]
          },
          window.location.href
      )
    }

    function showRoutine(dayNum) {
      return routine.value[dayNum]
    }

    function classMap(val) {
      const mapping = {
        'deathmatch': 'border-primary',
        'stretch': 'border-green-400',
        'game': 'border-secondary',
        'break': 'border-yellow-400'
      };
      return mapping[val]
    }

    function spaceMap(val) {
      const mapping = {
        '1': 'ml-4',
        '2': 'ml-24',
        '3': 'ml-48',
        '4': 'ml-72'
      };
      return mapping[val]
    }

    async function notifyRoutine() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data
          }
      )
      await axios.post(
          '/account/in/routine-notify/',
          {
            headers: {'X-CSRFToken': csrf_token},
            'email': emailRoutine.value,
            'tag': 'Valorant'
          }
      ).then(
          response => {
            if (response.data === 'Success') {
              toast.success('Welcome aboard', {
                timeout: 2000
              })
            }
          }
      ).catch(
          error => {
            toast.error('Some error occurred. Please contact us if it persists', {
              timeout: 8000
            })
          }
      )
    }

    onMounted(() => {
          initial();
        });

    return {
      isHidden,
      dataDump,
      isPremium,
      isLoading,
      currentTab,
      beforeTab,
      nowTab,
      tabs,
      getClick,
      sendTabInfo,
      showRoutine,
      classMap,
      spaceMap,
      notifyRoutine,
      emailRoutine,
    }
  }
}
</script>