import axios from "axios";

export const instance = axios.create({
    baseURL: 'https://logapiv1.swiftskill.gg',
    headers: {
        'common': {
            'Authorization': null
        }
    }

})

export const eventLog = async function eLog(event_type, event_info, event_path) {
    if (sessionStorage.getItem('session') !== null){
        let csrf_token;
        await instance.get(
            '/api/csrf-token/',
        ).then(
            response => {
                csrf_token = response.data
            }
        )
        await instance.post(
            '/api/event-add/',
            {
                headers: {
                    'X-CSRFToken': csrf_token
                },
                session_id: sessionStorage.getItem('session'),
                event_type: event_type,
                event_info: event_info,
                event_path: event_path
            }
        )
    } else {
        await sessionInit();
        await eventLog(event_type, event_info, event_path)
    }
};

export const sessionInit = async function initiateLog() {
    if (sessionStorage.getItem('session') === null) {
        let csrf_token_new;
        await instance.get(
            '/api/csrf-token/'
        ).then(
            response => {
                csrf_token_new = response.data
            }
        )
        await instance.post(
            '/api/session-add/',
            {
                headers: {
                    'X-CSRFToken': csrf_token_new
                },
                username: localStorage.getItem('username') !== null ? localStorage.getItem('username'): 'UR'
            }
        ).then(
            response => {
                sessionStorage.setItem('session', response.data)
                console.log(response.data)
            }
        )
    }
};
