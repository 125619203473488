<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <p class="text-3xl text-info px-6 py-3">My Teams</p>
        <div class="flex flex-col px-3 py-3 justify-center rounded-box">
          <div v-for="(v, k) in ownedLobbyList" class="card mx-2 my-2 bg-base-200">
            <div class="card-body">
              <div class="card-title">
                {{ v['name'] }}
              </div>
              <div class="bg-base-200 rounded-box py-3 mt-2">
                <p class="text-xs text-secondary mb-1">Members</p>
                <div class="badge badge-info px-3 py-3" v-for="member in v['members']">
                  <p>{{ member }}</p>
                </div>
              </div>
              <div class="border-base-300 border-b mb-3"></div>
              <div class="flex items-center justify-between rounded-box bg-base-100 opacity-75 hover:opacity-100 px-3 py-1">
                <p class="select-all text-2xs text-gray-400">
                  {{ getShareUri(v['uri']) }}
                </p>
                <button class="px-2 py-1 bg-warning text-black rounded-box hover:bg-yellow-400" @click="copyTeamLink(getShareUri(v['uri']))">Copy Invite
                </button>
              </div>
            </div>
          </div>
          <div v-for="(v, k) in memberLobbyList" class="card mx-2 my-2 bg-base-300 border border-base-100">
            <div class="card-body">
              <div class="card-title">
                {{ v['name'] }}
              </div>
              <p class="flex items-center justify-between rounded-box bg-base-100 px-3 py-1 select-all text-2xs text-gray-400">
                {{ getShareUri(v['uri']) }}
                <button class="px-2 py-1 bg-warning text-black rounded-box hover:bg-yellow-400" @click="copyTeamLink(getShareUri(v['uri']))">Copy</button>
              </p>
              <div class="bg-base-200 rounded-box px-3 py-3 mt-2">
                <p class="text-xs text-secondary ml-2 mb-1">Members</p>
                <div class="badge badge-info px-3 py-3 ml-1" v-for="member in v['members']">
                  <p>{{ member }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l-2 border-base-300 artboard hidden md:block">
      <div class="flex justify-between mt-6 ml-2 items-center">
        <div class="px-3 py-3 w-full border rounded-box border-base-100 bg-base-200">
          <div class="text-lg font-medium mb-3">
            Create your Team
          </div>
          <div>
            <div class="form-control space-y-2">
              <input type="text" class="input" placeholder="Team Name" v-model="team_name">
              <button class="btn bg-green-400 hover:bg-green-700" @click="createTeam()">Create</button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-between mt-6 ml-2 items-center bg-base-200 rounded-box px-4 py-4">
        <p class="text-info text-sm">
          We are consistently working towards improving our features. The following features will be coming soon: <br>
        </p>
        <ul class="list-disc text-sm self-start ml-5 mt-2">
          <li>Edit Teams</li>
          <li>In-depth team analysis</li>
          <li>Private Discord Bots</li>
          <li>and much more</li>
        </ul>
        <p class="text-sm text-warning mt-6">
          If you have any suggestions/feedback, please let us know at our <a class="text-secondary underline" href="https://discord.gg/bYwaNhZezT">Discord</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";
import Sidebar from "@/components/Sidebar";
import axios from "axios";
import {eventLog} from "@/log_api";
import {useRouter} from "vue-router";
import {useToast} from "vue-toastification";

export default {
  name: 'TeamPage',
  components: {
    Sidebar
  },
  setup(){
    const isHidden = ref(false);
    const ownedLobbyList = ref(null);
    const memberLobbyList = ref(null);
    const router = useRouter();

    const toast = useToast();

    const team_name = ref(null);
    const team_description = ref('');
    const team_uri = ref('');

    // Utility
    function getShareUri(val) {
      return `https://swiftskill.gg/teams/invite/${val}`
    }

    function copyTeamLink(val) {
      navigator.clipboard.writeText(val);
      toast.success('Copied link to clipboard', {
        timeout: 3000
      })
    }

    async function initial() {
      await axios.get(
          '/account/in/team-display/'
      ).then(
          response => {
            ownedLobbyList.value = response.data['Owned'];
            memberLobbyList.value = response.data['Member'];
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )
    }

    async function createTeam() {
      let csrf_token;
      await axios.get(
          '/valorant/csrf-token'
      ).then(
          response => {
            csrf_token = response.data;
          }
      )
      await axios.post(
          '/account/in/team-create/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'team_uri': team_uri.value,
            'team_name': team_name.value,
            'team_description': team_description.value,
          }
      ).then(
          response => {
            if (response.data === 'Success') {
              initial();
              team_uri.value = '';
              team_description.value = null;
              team_name.value = null;
              toast.success('Team Created Successfully', {
                timeout: 3000
              });
            } else if (response.data === 'Duplicate') {
              toast.error('Team URI exists. Please retry with a different URI', {
                timeout: 6000
              });
            }
          }
      )
      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Spaces',
            'button_name': 'Create Space'
          },
          window.location.href
      )
    }

    // calling initial at start
    initial();

    return {
      isHidden,
      createTeam,
      team_name,
      team_uri,
      team_description,
      ownedLobbyList,
      memberLobbyList,
      getShareUri,
      copyTeamLink,
    }
  }
}
</script>