<template>
  <div class="navbar mb-2 bg-base-100 text-neutral-content rounded-box">
    <div class="px-2 py-2 mx-2 navbar-start">
      <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/logo+white.webp" alt="SwiftSkill" class="w-3/12">
    </div>
    <div class="hidden px-2 navbar-center lg:flex">
      <div class="flex items-stretch">
        <router-link to="/" class="btn btn-sm border-none bg-opacity-0 hover:bg-primary">
          Home
        </router-link>
        <router-link to="/about" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
          About
        </router-link>
        <a href="https://blog.swiftskill.gg" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
          Guides
        </a>
        <div v-if="$store.state.isAuthenticated">
          <router-link to="/dashboard" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
            Dashboard
          </router-link>
          <router-link to="/pricing" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
            Pricing
          </router-link>
          <button class="btn btn-sm border-none rounded-btn bg-opacity-0 hover:bg-primary" @click="logout">Logout</button>
        </div>
        <div v-else>
          <router-link to="/login" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
            Login
          </router-link>
          <router-link to="/signup" class="btn border-none btn-sm rounded-btn bg-opacity-0 hover:bg-primary">
            Sign Up
          </router-link>
        </div>
      </div>
    </div>
    <div class="navbar-end dropdown">
      <div class="btn btn-square btn-ghost flex md:hidden m-1" tabindex="0">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             class="inline-block w-6 h-6 stroke-current">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
        </svg>
      </div>
      <ul tabindex="0" class="p-2 mt-40 shadow menu dropdown-content bg-base-200 rounded-box w-52">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <div v-if="$store.state.isAuthenticated">
          <li>
            <router-link to="/pricing">Pricing</router-link>
          </li>
          <li>
            <router-link to="/dashboard">Dashboard</router-link>
          </li>
        </div>
        <div v-else>
          <li>
            <router-link to="/login">Login</router-link>
          </li>
          <li>
            <router-link to="/signup">Sign Up</router-link>
          </li>
        </div>
      </ul>
    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'Navbar',
  data() {
    return {
      username: localStorage.getItem('username')
    }
  },
  methods: {
    logout() {
      axios
          .post('accounts/token/logout')
          .then(response => {
            axios.defaults.headers.common['Authorization'] = ''

            localStorage.removeItem('username')
            localStorage.removeItem('userid')
            localStorage.removeItem('token')

            this.$store.commit('removeToken')
            this.$router.push('/logout')
          }).catch(
          error => {
            if (error.response.status === 401) {
              this.$router.push('/login')
            }
          }
      )
    }
  }
}

</script>