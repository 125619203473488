<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box">
      <FullScreenLoader v-if="isLoading"></FullScreenLoader>
      <div class="flex flex-row justify-between md:justify-end space-x-4 mt-6 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
      </div>
      <div class="overflow-x-auto flex-wrap justify-between w-full mt-6" style="height: 95vh">
        <div class="px-6 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <p class="text-lg leading-10 text-secondary uppercase mb-5 border-b border-gray-900">Overview</p>
          <div class="flex flex-wrap flex-col md:flex-row justify-between">
            <p class="text-lg">
              Find your monthly performance review!
            </p>
          </div>
        </div>
        <div class="flex mt-3 flex-none justify-center">
          <div class="tabs">
            <a class="tab tab-bordered uppercase" v-for="(tab, ind) in tabs" :class="{'tab-active': currentTab === ind}"
               @click="getClick('prev'); currentTab = ind; getClick('now'); sendTabInfo()">{{ tab }}</a>
          </div>
        </div>
        <div v-show="currentTab === 0" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <p class="text-2xl uppercase text-secondary tracking-wide leading-loose px-1">
            MONTH Review
          </p>
          <div class="" v-for="(weekData, monthName) in dataDump['month_details']">
            <div class="flex bg-base-100 rounded-box px-4 py-4 mb-2 items-center shadow-lg"
                 v-for="(weekValues, weekNum) in weekData">
              <div class="flex space-x-4 items-center" v-if="weekNum > dataDump['curr_week']">
                <p>
                  Week not yet unlocked
                </p>
                <i class="uil uil-grin-tongue-wink text-4xl text-primary"></i></div>
              <div v-else class="flex w-full">
                <div class="flex flex-col flex-none mr-5 pr-4 items-center justify-center border-r-2">
                  <p class="mb-1 px-2 rounded text-2xl"
                     :class="[dataDump['curr_week'] === Number(weekNum) ? 'bg-primary' : '']"
                     :title="[dataDump['curr_week'] === Number(weekNum) ? 'Current Week' : '']">{{ weekNum }}</p>
                  <p class="text-xs">{{ weekValues['range'][0] }}</p>
                  <p class="text-xs">{{ weekValues['range'][1] }}</p>
                </div>
                <div class="flex items-center justify-between w-full">
                  <p class="text-2xl flex flex-col flex-none">
                    <span>{{ weekValues['summary']['wins'] }}W</span>
                    <span>{{ weekValues['summary']['losses'] }}L</span>
                  </p>
                  <div class="flex-none">
                    <p>KDA</p>
                    <p>
                      {{ weekValues['summary']['kills'] }}
                      {{ weekValues['summary']['deaths'] }}
                      {{ weekValues['summary']['assists'] }}
                    </p>
                  </div>
                  <div class="flex flex-col">
                    <img v-if="weekValues['summary']['agents'][1] !== ''" width="80"
                         :src="agentImg(weekValues['summary']['agents'][1])">
                    <i v-else class="uil uil-exclamation-octagon text-7xl ml-10 text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="currentTab === 1" class="px-3 py-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box">
          <BarChart
              :chart-data="barChart"
              :options="barChartOption"
              :height='200'
              class="px-4 py-4"></BarChart>
        </div>
      </div>
    </div>
    <div class="w-64 text-lg border-l border-gray-900 artboard hidden md:block">
    </div>
  </div>
</template>
<script>

import Sidebar from "@/components/Sidebar";
import {BarChart} from 'vue-chart-3';
import {ref, onMounted, computed} from "vue";
import axios from "axios";
import store from "@/store";
import {useRouter} from "vue-router";
import FullScreenLoader from "@/components/FullScreenLoader";
import {eventLog} from "@/log_api";

export default {
  name: 'CalendarRoutine',
  components: {
    Sidebar,
    BarChart,
    FullScreenLoader
  },
  setup() {
    const isHidden = ref(false);
    const isLoading = ref(false);
    const isPremium = ref(false);
    const router = useRouter();

    const currentTab = ref(0);
    const beforeTab = ref(0);
    const nowTab = ref(0);
    const tabs = ref(['Monthly', 'Weekday']);

    let dataDump = ref({});

    async function initial() {
      let csrf_token = '';
      isLoading.value = true;
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true
            }
          }
      )
      await axios.get(
          '/valorant/csrf-token/'
      ).then(response => {
            csrf_token = response.data
          }
      ).catch(
          error => {
            if (error.response.status === 401) {
              router.push('/login')
            }
          }
      )
      await axios.post(
          '/valorant/routine/v1/',
          {
            headers: {
              'X-CSRFToken': csrf_token
            },
            'puuid': store.state.user.uuid
          }
      ).then(response => {
            dataDump.value = response.data;
            isLoading.value = false;
          }
      ).catch(error => {
            console.log(JSON.stringify(error))
          }
      )
    }

    function getClick(type) {
      if (type === 'prev') {
        beforeTab.value = currentTab.value;
      } else {
        nowTab.value = currentTab.value;
      }
    }

    function sendTabInfo() {
      // event trigger
      eventLog(
          'tab_navigation',
          {
            'page_name': 'Monthly',
            'tab_name': tabs.value[nowTab.value],
            'prev_tab': tabs.value[beforeTab.value]
          },
          window.location.href
      )
    }

    function agentImg(val) {
      if (val === "Enemy KAY/O") {
        val = "Enemy KAYO"
      } else if (val === "KAY/O") {
        val = "KAYO"
      }
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val}.png`
    }

    const barChart = computed(() => {
      return {
        labels: dataDump.value.weekdaymap_labels,
        datasets: [
          {
            type: 'bar',
            label: 'Losses',
            fill: false,
            data: dataDump.value.weekdaymap_losses,
            backgroundColor: [
              'rgba(233, 69, 96, 0.2)',
            ],
            borderColor: [
              'rgba(233, 69, 96, 1)',
            ],
            borderWidth: 1,
            borderRadius: 10,
          },
          {
            type: 'bar',
            label: 'Wins',
            fill: false,
            data: dataDump.value.weekdaymap_wins,
            backgroundColor: [
              'rgba(120, 204, 174, 0.2)',
            ],
            borderColor: [
              'rgba(120, 204, 174, 1)',
            ],
            borderWidth: 1,
            borderRadius: 10,
          },
        ]
      }
    });

    const barChartOption = computed(() => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: 'Count',
              font: {
                family: 'Poppins',
                size: 12
              }
            }
          }
        },
      }
    });

    onMounted(() => {
          initial();
        }
    );

    return {
      isHidden,
      dataDump,
      isPremium,
      isLoading,
      agentImg,
      currentTab,
      beforeTab,
      nowTab,
      tabs,
      getClick,
      sendTabInfo,
      barChart,
      barChartOption,
    }
  }
}
</script>