<template>
  <Navbar />
  <div class="flex items-center flex-col">
    <div>
      <div class="form-control">
        <p class="text-lg text-start mt-4 mb-4 leading-loose tracking-wide text-info">Password Reset</p>
        <form @submit.prevent="resetPassword">
          <label class="input-group mb-4 mt-4">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </span>
            <input type="text" placeholder="Email" v-model="user_email" class="input input-bordered">
          </label>
          <div class="mb-3">
            <button class="button btn btn-success">Reset Password</button>
          </div>
        </form>
        <div ref="responseDiv"></div>
      </div>
    </div>
  </div>

</template>
<script>
import Navbar from "@/components/Navbar";
import {ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import {eventLog, sessionInit} from "@/log_api";
export default {
  name: 'PasswordReset',
  components: {Navbar},
  setup() {
    const store = useStore();
    const isAuthenticated = store.state.isAuthenticated;
    const user_email = ref('')
    const processing = ref(false)
    const responseDiv = ref(null)

    async function resetPassword(){
      processing.value = true;
      await axios.post(
          '/account/in/password-reset/',
          {
            'user_email': user_email.value
          }
      ).then(
          response => {
            if (response.status === 204){
              processing.value = false;
              responseDiv.value.textContent = 'Please check your email for password reset link'
            } else{
              processing.value = false;
              responseDiv.value.textContent = 'Please check if your email is correct. If the same error occurs again, please contact us'
            }
          }
      )
    }

    // session start
    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Password Reset',
          'page_details': {}
        },
        window.location.href
    )

    return {
      isAuthenticated,
      resetPassword,
      processing,
      user_email
    }
  }
}
</script>