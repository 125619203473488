<template>
  <div class="mx-auto w-full" :class="{container: route.path !== '/'}" id="wrapper">
    <section class="w-full">
      <router-view/>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { provideToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
  name: 'App',
  setup(){
    provideToast({ maxToasts: 5 });
    const store = useStore();
    const route = useRoute();
    store.commit('initializeStore')
    const token = store.state.token;
    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
    return {
      route
    }
  },
  // beforeCreate() {
  //   this.$store.commit('initializeStore')
  //   const token = this.$store.state.token
  //   if (token) {
  //     axios.defaults.headers.common['Authorization'] = "Token " + token
  //   } else {
  //     axios.defaults.headers.common['Authorization'] = ""
  //   }
  // }
}
</script>

