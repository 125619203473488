<template>
  <footer class="p-10 footer bg-base-100 border-t-2 border-base-300 text-base-content">
    <div>
      <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/logo+white.webp" alt="SwiftSkill" class="w-3/12">
      <p>SwiftSkill Ltd.
        <br>For gamers, by gamers.
      </p>
      <p class="text-sm text-gray-500">
        SwiftSkill isn't endorsed by Riot Games and doesn't <br> reflect the views or opinions of Riot Games or <br> anyone
        officially involved in producing or <br> managing Riot Games properties. Riot Games, and <br> all associated properties are
        trademarks or <br> registered trademarks of Riot Games, Inc.
      </p>
    </div>
    <div>
      <span class="footer-title">Social</span>
      <a class="link link-hover" href="https://twitter.com/swiftskillggs">Twitter</a>
      <a class="link link-hover" href="https://discord.gg/bYwaNhZezT">Discord</a>
      <a class="link link-hover" href="https://instagram.com/swiftskillggs">Instagram</a>
      <a class="link link-hover" href="https://support.swiftskill.gg">Support</a>
    </div>
    <div>
      <span class="footer-title">Company</span>
      <router-link to="/about" class="link link-hover border-none">About us</router-link>
      <router-link to="/affiliates" class="link link-hover border-none">Affiliates</router-link>
      <a href="https://blog.swiftskill.gg" class="link link-hover border-none">
          Guides
      </a>
    </div>
    <div>
      <span class="footer-title">Legal</span>
      <router-link to="/tos" class="link link-hover border-none">Terms of use</router-link>
      <router-link to="/privacy-policy" class="link link-hover border-none">Privacy policy</router-link>
      <router-link to="/affiliate-tos" class="link link-hover border-none">Affiliate Terms of Service</router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>