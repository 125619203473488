import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueMeta from "vue-meta";
import './assets/tailwind.css'
import "@/assets/styles/main.css";
import './registerServiceWorker'
import TOAST, {POSITION} from "vue-toastification";
import './gtm'

axios.defaults.baseURL = 'https://apiv2.swiftskill.gg'

const options = {
    toastClassName: "swiftskill-toast"
}

createApp(App).use(store).use(router, axios, VueMeta).use(TOAST, options).mount('#app')
