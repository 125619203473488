<template>

</template>

<script>

import {useRoute} from "vue-router";
import axios from "axios";

export default {
  name: 'RiotCallBack',
  setup(){

  }
}

</script>