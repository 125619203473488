<template>
  <div class="flex mt-2 flex-row space-x-6 justify-center">
    <Sidebar
        :menu="{
      'dash': {'href': '/dashboard', 'title': 'Dashboard', 'icon': 'uil uil-tachometer-fast-alt'},
      // 'car': {'href': '/dashboard/career', 'title': 'Career', 'icon': 'uil uil-history'},
      'mapwise': {'href': '/dashboard/mapwise', 'title': 'Mapwise', 'icon': 'uil uil-map'},
      'profileCard': {'href': '/profile-card', 'title': 'Profile Card', 'icon': 'uil uil-user-square'},
      //'spaces': {'href': '/spaces', 'title': 'Spaces', 'icon': 'uil uil-rocket'},
      //'teams': {'href': '/teams', 'title': 'Teams', 'icon': 'uil uil-users-alt'},
      'monthly': {'href': '/monthly-review', 'title': 'Monthly Review', 'icon': 'uil uil-schedule'},
      'routine': {'href': '/routine', 'title': 'Routine', 'icon': 'uil uil-dumbbell'},
    }" v-if="!isHidden"/>
    <div class="flex-none w-3/5 items-start px-3 rounded-box" v-if="isLoading">
      <DashboardLoader/>
    </div>
    <div class="flex-none w-3/5 items-start px-3 rounded-box" v-else>
      <div class="flex-none w-3/5 items-start px-3 rounded-box" v-if="firstTime">
        <DashboardLoader v-if="isLoading"/>
        <FullScreenLoader/>
      </div>
      <div class="flex flex-row justify-between md:justify-end space-x-4 items-end">
        <a class="md:hidden" @click="isHidden = !isHidden">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
          </svg>
        </a>
        <button class="btn bg-primary hover:bg-base-300 hover:border hover:border-primary mt-6" @click="matchRefresh()">
          <svg ref="iconLoad" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
          </svg>
          <span class="hidden md:block ml-3">Refresh Matches</span>
        </button>
      </div>
      <div class="flex items-center justify-end space-x-4 mt-3 px-6">
        <span>Toggle View</span>
        <input ref="easyToggle" @click="toggleThyCharts" type="checkbox" class="toggle toggle-secondary" checked/>
      </div>
      <div v-if="toggleState===true" class="flex items-center justify-around bg-base-100 shadow-lg border border-gray-900 rounded-box px-3 py-2 mt-2">
        <LineChart
              :chart-data="overviewChart"
              :options="overviewChartOption"
              :height='200'
              class="w-4/5 px-4 py-4"></LineChart>
        <p class="text__Vertical border-l border-gray-900 px-4 font-black text-2xl">
          {{ histWins }}W {{ histLosses }}L
        </p>
      </div>
      <div v-else class="flex items-center justify-center bg-base-100 border border-gray-900 shadow-lg rounded-box px-3 py-3 mt-2">
        <table class="table">
          <tr class="bg-gray-900">
            <th></th>
            <th>Kills</th>
            <th>Deaths</th>
            <th>KD</th>
            <th>Damage</th>
            <th>HS %</th>
            <th>Score</th>
            <th>Win %</th>
          </tr>
          <tr>
            <th class="bg-gray-900">Competitive</th>
            <td v-for="(v, k) in histCompetitive"> {{ v }} </td>
          </tr>
          <tr>
            <th class="bg-gray-900">Unrated</th>
            <td v-for="(v, k) in histUnrated"> {{ v }} </td>
          </tr>
          <tr>
            <th class="bg-gray-900">Custom</th>
            <td v-for="(v, k) in histCustom"> {{ v }} </td>
          </tr>
        </table>
      </div>
      <div ref="parentList" class="overflow-x-auto overflow-y-auto w-full mt-6" style="height: 50vh">
        <p class="text-secondary uppercase mb-4">Your matches</p>
        <div @click="matchwiseStat(key)" :id="'matchids__' + key"
             class="flex items-center px-4 py-2 space-x-12 border-l-4 border-red-600 shadow-lg mb-4 h-24 cursor-pointer"
             :class="{'border-green-300': value[3][5] === 'Won'}" v-for="(value, key) in matches" :key="key">
          <div class="flex flex-col space-y-2 w-2/5">
            <p class="text-xl">{{ value[3][3] }} - {{ value[3][4] }}</p>
            <p class="text-start text-gray-400">{{ value[1] }}</p>
          </div>
          <div class="flex flex-col space-y-2 w-2/5">
            <p class="text-lg capitalize tracking-wide">{{ value[2] }}</p>
            <span class="text-secondary">{{ value[3][6] }} / {{ value[3][7] }} / {{ value[3][8] }}</span>
          </div>
          <div class="flex items-center align-bottom space-x-4 w-full">
            <p class="text-md text-gray-400 w-1/5">{{ value[0] }}</p>
            <i class="uil uil-notes"
               :class="{'text-green-400': value[5] === true, 'text-gray-500': value[5] === false}"></i>
          </div>
          <div class="group">
            <label></label>
            <input type="checkbox" class="checkbox" :id="key" @click.stop @click="checkboxClick(key)">
          </div>
          <div class="flex-none flex justify-end group">
            <img class="mask-heart bg-gray-300" :src="agentImg(value[3][2])" width="80">
          </div>
        </div>
      </div>
    </div>
    <div class="w-64 artboard border-l border-gray-900 px-2 hidden md:block">
      <div class="filtersDiv form-control artboard shadow-md mb-3 mb-8 p-3">
        <select ref="mapSelect" class="select border border-gray-900 mt-1 mb-1">
          <option value="" selected>Map</option>
          <option value="Bind">Bind</option>
          <option value="Haven">Haven</option>
          <option value="Breeze">Breeze</option>
          <option value="Ascent">Ascent</option>
          <option value="Icebox">Icebox</option>
          <option value="Split">Split</option>
          <option value="Fracture">Fracture</option>
        </select>
        <select ref="agentSelect" class="selectDD select border border-gray-900 mt-1 mb-1">
          <option value="" selected>Agent</option>
          <option value="Astra">Astra</option>
          <option value="Breach">Breach</option>
          <option value="Brimstone">Brimstone</option>
          <option value="Chamber">Chamber</option>
          <option value="Cypher">Cypher</option>
          <option value="Jett">Jett</option>
          <option value="KAY/O">KAY/O</option>
          <option value="Killjoy">Killjoy</option>
          <option value="Neon">Neon</option>
          <option value="Omen">Omen</option>
          <option value="Phoenix">Phoenix</option>
          <option value="Raze">Raze</option>
          <option value="Reyna">Reyna</option>
          <option value="Sage">Sage</option>
          <option value="Skye">Skye</option>
          <option value="Sova">Sova</option>
          <option value="Viper">Viper</option>
          <option value="Yoru">Yoru</option>
        </select>
        <select ref="gameModeSelect" class="selectDD select border border-gray-900 mt-1 mb-1">
          <option value="" selected>Mode</option>
          <option value="competitive">Competitive</option>
          <option value="unrated">Unrated</option>
          <option value="custom">Custom</option>
        </select>
        <select ref="episodeSelect" @change="episodeChange" class="selectDD select border border-gray-900 mt-1 mb-1">
          <option selected value="">Episode</option>
          <option v-for="(val, key) in episodes" :value="key">{{ val.name }}</option>
        </select>
        <select ref="actSelect" class="hidden selectDD select border border-gray-900 mt-1 mb-1">
          <option selected value="">Act</option>
        </select>
        <p class="text-sm text-center py-2"><span class="font-bold">{{ currMatchNum }}</span> Matches available</p>
        <p v-if="!isPremium" class="text-sm text-center py-2">
          <router-link to="/pricing" class="text-secondary">Upgrade to Premium</router-link>
          to view all <span class="font-bold">{{ overallMatchesCount }}</span> Matches
        </p>
        <button class="btn btn-outline mt-4 mb-2" @click="onClickFilter">Apply</button>
        <button class="btn btn-outline" @click="clearFilter">Clear</button>
      </div>
      <div class="artboard shadow-md mb-3 bg-base-200 bg-opacity-20 border border-gray-900 rounded-box p-3 ml-1">
        <p class="text-sm">Select two matches to compare</p>
        <button class="btn btn-primary mb-3 mt-3" id="compareBut" disabled @click="compareMatches"
                onclick="location.href ='#compare-matches'">
          Compare
        </button>
        <button class="btn border border-gray-900 bg-base-100" @click="clearCheckboxSelection">Clear Selection</button>
        <div id="compare-matches" class="flex flex-col modal">
          <div v-if="modalLoading">
            Loading
          </div>
          <div v-else class="bg-base-100 px-4 py-4 rounded-box">
            <div class="items-center">
              <table class="table">
                <thead>
                <tr>
                  <th>Stat</th>
                  <th>Match 1</th>
                  <th>Match 2</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v, k) in compareData">
                  <td>{{ k }}</td>
                  <td v-for="i in [0, 1]">
                    {{ v[i] }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-action">
              <button class="btn btn-secondary" @click="clearCheckboxSelection" onclick="history.back()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style>
.loader {
  animation: circbut 2s linear infinite;
}

@keyframes circbut /*--for firefox--*/
{

  from {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }

}
</style>

<script>
import axios from "axios";
import Sidebar from "@/components/Sidebar";
import NavbarMob from "@/components/NavbarMob";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import DashboardLoader from "@/components/DashboardLoader";
import store from "@/store";
import Loader from "@/components/Loader";
import FullScreenLoader from "@/components/FullScreenLoader";
import {sessionInit, eventLog} from "@/log_api";
import {useToast, POSITION} from "vue-toastification";
import {Chart, registerables} from "chart.js";
import {LineChart} from 'vue-chart-3';

Chart.register(...registerables);

export default {
  name: 'Dashboard',
  components: {
    FullScreenLoader,
    DashboardLoader,
    Sidebar,
    NavbarMob,
    LineChart,
  },
  setup() {
    const matches = ref({});
    const acts = ref({});
    const episodes = ref({});
    const actmap = ref({});
    const totalMatches = ref(0);
    const currMatchNum = ref(0);
    const iconLoad = ref(null);
    const parentList = ref(null);
    const notesMatch = ref(false);
    const notesData = ref('');
    const isLoading = ref(false);
    const firstTime = ref(null);
    const overallMatchesCount = ref(0);

    //polling interval
    const pollInterval = {
      'firstTime': 6000,
      'refresh': 2000
    };

    // Check Box
    const checkboxArr = ref([]);

    // Compare Data
    const modalLoading = ref(false);
    const compareData = ref({});

    // Filter Dropdowns
    const mapSelect = ref(null);
    const agentSelect = ref(null);
    const gameModeSelect = ref(null);
    const episodeSelect = ref(null);
    const actSelect = ref(null);

    const fetchingComplete = ref(localStorage.getItem('fetchingComplete'));
    const router = useRouter();
    const $store = useStore();
    const detailDiv = ref(null);
    const isHidden = ref(false);

    const isPremium = ref(false);
    const toast = useToast();

    // Easy Toggle
    const easyToggle = ref(null);
    const toggleState = ref(true);

    // Overview
    const histChartKills = ref({});
    const histChartDeaths = ref({});
    const histChartLength = ref([]);
    const histWins = ref(0);
    const histLosses = ref(0);
    const histCompetitive = ref({});
    const histUnrated = ref({});
    const histCustom = ref({});

    // Functions
    function matchwiseStat(matchid) {
      router.push({name: 'Matchwise', params: {'matchid': matchid}})
    }

    async function matchRefresh() {
      iconLoad.value.classList = 'h-6 w-6 loader';

      let uDetails = {
        r_id: '',
        puuid: '',
        r_region: ''
      };

      await axios
          .get(
              "/account/in/me/"
          ).then(
              response => {
                uDetails['puuid'] = response.data['uuid']
                uDetails['r_region'] = response.data['region']
              }
          )

      await axios.post(
          '/valorant/matchupdate/v1/',
          {
            'puuid': uDetails['puuid'],
            'rregion': uDetails['r_region']
          }
      )
          .then(
              response => {
                localStorage.setItem('task_id', response.data);
                localStorage.setItem('fetchingComplete', 'false');
                pollData('refresh');
              }
          )

      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Dashboard',
            'button_name': 'Refresh Matches'
          },
          window.location.href
      )
    }

    async function pollData(flag) {
      if (localStorage.getItem('fetchingComplete') === 'false') {
        // check if task stil running
        await axios.post(
            '/valorant/celery-task/v1/',
            {
              'task_id': localStorage.getItem('task_id')
            }
        ).then(
            response => {
              if (response.data === 'success') {
                localStorage.setItem('fetchingComplete', 'true');
                localStorage.setItem('skh', 'false');
                localStorage.removeItem('skh');
                firstTime.value = false;
                // console.log('Polling Complete')
                iconLoad.value.classList = 'h-6 w-6';
                toast.success('Successfully updated matches', {
                  timeout: 3000,
                })
              } else {
                // console.log('Polling', firstTime.value, isLoading.value)
                setTimeout(pollData, 30000) // pollInterval[flag]
              }
            }
        )

        // refresh matchlist
        let csrf_token = "";
        await axios.get(
            '/valorant/csrf-token/'
        ).then(
            response => {
              csrf_token = response.data;
              if (flag === 'firstTime') {
                isLoading.value = true;
              }
            }
        ).catch(
            error => {
              if (error.response.status === 401) {
                router.push('/login')
              }
            }
        )

        await axios.post(
            '/valorant/matchlist/v1/',
            {
              headers: {'X-CSRFToken': csrf_token},
              'puuid': $store.state.user.uuid,
              'premium': isPremium.value
            }
        ).then(
            response => {
              matches.value = response.data['compdicts']
              acts.value = response.data['acts']
              episodes.value = response.data['episodes']
              actmap.value = response.data['actmap']
              totalMatches.value = response.data['totalmatches']
              currMatchNum.value = Object.keys(matches.value).length
              overallMatchesCount.value = response.data['fullmatcheslen']
            }
        ).catch(error => {
              console.log(error)
            }
        ).finally(() => {
              if (flag === 'firstTime') {
                isLoading.value = false;
              }
            }
        )
      }
    }


    // Hooks for loading data on
    onMounted(async () => {
      await axios.get(
          '/account/in/p-checks/'
      ).then(
          response => {
            if (response.data === 'Premium') {
              isPremium.value = true
            }
          }
      )

      // console.log('on mount called')
      if (localStorage.getItem('skh') === 'true') { // if firsttime keep polling matchlist
        firstTime.value = true;
        await pollData('firstTime');
        await axios.post(
            '/account/in/user-signup-referral/',
            {
              'referrer': localStorage.getItem('new_user_referrer')
            }
        ).then(
            response => {
              if (response.data === 'success') {
                localStorage.removeItem('new_user_referrer');
                toast.success('Successful Referral', {
                  timeout: 3000,
                })
              } else {
                toast.error('Unsuccessful Referral', {
                  timeout: 3000,
                })
              }
            }
        )
      } else { // else run normally
        let csrf_token = "";
        await axios.get('/valorant/csrf-token/').then(response => {
          csrf_token = response.data;
          isLoading.value = true;
        }).catch(
            error => {
              if (error.response.status === 401) {
                router.push('/login')
              }
            }
        )

        await axios.get(
            '/account/in/detail-gate'
        ).then(
            response => {
              // console.log(response)
            }
        )

        await axios.post(
            '/valorant/matchlist/v1/',
            {
              headers: {'X-CSRFToken': csrf_token},
              'puuid': $store.state.user.uuid,
              'premium': isPremium.value
            }
        ).then(
            response => {
              matches.value = response.data['compdicts'];
              acts.value = response.data['acts'];
              episodes.value = response.data['episodes'];
              actmap.value = response.data['actmap'];
              totalMatches.value = response.data['totalmatches'];
              overallMatchesCount.value = response.data['fullmatcheslen'];
              histChartKills.value = response.data['hist_kills'];
              histChartDeaths.value = response.data['hist_deaths'];
              histChartLength.value = response.data['hist_x'];
              histWins.value = response.data['hist_wins'];
              histLosses.value = response.data['hist_loss'];
              histCompetitive.value = response.data['hist_competitive'];
              histUnrated.value = response.data['hist_unrated'];
              histCustom.value = response.data['hist_custom'];
            }
        ).catch(
            error => {
              console.log(error)
            }
        ).finally(() => {
              isLoading.value = false;
            }
        )
        currMatchNum.value = totalMatches.value;
      }

      // session innit
      await sessionInit();
      // event trigger
      await eventLog(
          'page_view',
          {
            'page_name': 'Dashboard',
            'page_details': {
              'premium': isPremium.value,
            }
          },
          window.location.href
      )
    });

    function showNotes(key) {
      notesMatch.value = true;
    }

    // Utility
    function removeFromArray(arr, val) {
      return arr.filter(function (f) {
        return f !== val
      });
    }

    function pushToArray(arr) {
      let dummyArr = [];
      arr.forEach(val => dummyArr.push(val));
      return dummyArr;
    }


    // Compare Functions
    async function compareMatches() {
      const matchIds = checkboxArr.value;
      let csrf_token;

      await axios.get(
          '/valorant/csrf-token/'
      ).then(
          response => {
            csrf_token = response.data;
            modalLoading.value = true;
          }
      );

      await axios.post(
          '/valorant/matchcompare/v1/',
          {
            headers: {
              'X-CSRFToken': csrf_token,
            },
            'matchlist': matchIds,
            'puuid': store.state.user.uuid,
          }
      ).then(
          response => {
            compareData.value = response.data;
            modalLoading.value = false;
          }
      );

      // event trigger
      await eventLog(
          'button_click',
          {
            'page_name': 'Dashboard',
            'button_name': 'Compare Matches'
          },
          window.location.href
      )
    }

    function checkboxClick(key) {
      if (checkboxArr.value.length < 1) { // empty

        // Push Value
        checkboxArr.value.push(key);

      } else if (checkboxArr.value.length < 2) { // 1 element

        if (pushToArray(checkboxArr.value).includes(key) === false) {

          // Push Value
          checkboxArr.value.push(key);

          // Activate compare button
          document.getElementById('compareBut').disabled = false;

          // Disable rest of the checkboxes
          document.querySelectorAll('input[type=checkbox]').forEach(
              (e) => {
                if (pushToArray(checkboxArr.value).includes(e.id) === false) {
                  e.disabled = true;
                }
              }
          )
        } else {

          // Remove Value
          checkboxArr.value = removeFromArray(checkboxArr.value, key);
        }
      } else if (checkboxArr.value.length < 3) { // 2 element

        // Remove Value
        checkboxArr.value = removeFromArray(checkboxArr.value, key);

        // Enable rest of the checkboxes
        document.querySelectorAll('input[type=checkbox]').forEach(
            (e) => {
              e.disabled = false;
            }
        )
      }
    }

    function clearCheckboxSelection() {
      checkboxArr.value = [];
      compareData.value = {};
      document.getElementById('compareBut').disabled = true;
      document.querySelectorAll('input[type=checkbox]').forEach(
          (e) => {
            if (e.checked) {
              e.checked = false
            } else if (e.disabled) {
              e.disabled = false
            }
          }
      )
    }

    // Sneak Peek Functions
    function showDetail(key) {
      const parDiv = detailDiv.value;
      parDiv.removeChild(parDiv.lastChild);
      let childDiv = document.createElement('div')
      childDiv.textContent = `${matches.value[key][3][2]} // ${matches.value[key][3][5]} // ${matches.value[key][3][6]} K // ${matches.value[key][3][7]} D // ${matches.value[key][3][8]} A`
      parDiv.append(childDiv)
      return detailDiv
    }

    function removeDetail() {
      const parDiv = detailDiv.value;
      while (parDiv.lastChild) {
        parDiv.removeChild(parDiv.lastChild)
      }
      let a = document.createElement('p');
      a.classList.add('text-sm');
      a.textContent = 'Hover over a match to get a sneak peek!'
      parDiv.append(a);

    }


    // Toggle Functions
    function toggleThyCharts() {
      toggleState.value = easyToggle.value.checked;
    }

    // Filter Functions
    function episodeChange() {
      let episodeval = episodeSelect.value;
      let actbut = actSelect.value;

      // Act button Show/Hide
      if (episodeval.value !== '') {
        actbut.classList.remove('hidden');
        // clear prev vals if any
        document.querySelectorAll('.tempo').forEach(e => e.remove());
        // populate new values
        for (let actid of actmap.value[episodeval.value]) {
          let opt = document.createElement('option');
          opt.classList.add("tempo");
          opt.value = actid;
          opt.textContent = acts.value[actid]['name'];
          actbut.append(opt);
        }
      } else {
        actbut.classList.add('hidden');
        document.querySelectorAll('.tempo').forEach(e => e.remove());
      }
    }

    function onClickFilter() {
      let map = mapSelect.value.value;
      let agent = agentSelect.value.value;
      let mode = gameModeSelect.value.value;
      let episode = episodeSelect.value.value;
      let act = actSelect.value.value;

      let newdata = applyFilters(matches.value, map, agent, mode, episode, act);

      currMatchNum.value = Object.keys(newdata).length

      for (let child of parentList.value.children) {
        if (child.id.includes('matchids') === true) {
          child.classList.remove('hidden'); //$(this).show()
        }
        if (Object.keys(newdata).includes(child.id) === false) {
          if (child.id.includes('matchids') === true) {
            child.classList.add('hidden'); //$(this).hide()
          }
        }
      }

      // event trigger
      eventLog(
          'button_click',
          {
            'page_name': 'Dashboard',
            'button_name': 'Filter'
          },
          window.location.href
      )
    }

    function clearFilter() {
      mapSelect.value.value = '';
      agentSelect.value.value = '';
      gameModeSelect.value.value = '';
      episodeSelect.value.value = '';
      actSelect.value.value = '';
      actSelect.value.classList.add('hidden');
      document.querySelectorAll('.tempo').forEach(e => e.remove());

      currMatchNum.value = totalMatches.value

      for (let child of parentList.value.children) {
        if (child.id.includes('matchids') === true) {
          child.classList.remove('hidden');
        }
      }
    }

    function filterthyfilters(arr, val) {
      if (val === '') {
        return arr;
      } else {
        return [val];
      }
    }

    function filteracts(episodeval, actval) {
      let retids = [];
      if (episodeval !== '') {
        if (actval !== '') {
          retids.push(actval)
          return retids
        } else {
          for (let el of actmap.value[episodeval]) {
            retids.push(el)
          }
          return retids
        }
      } else {
        for (const [k, v] of Object.entries(actmap.value)) {
          for (let el of v) {
            retids.push(el)
          }
        }
        return retids
      }
    }

    function applyFilters(data, map, agent, mode, episode, act) {
      let map_values = ['Bind', 'Breeze', 'Ascent', 'Icebox', 'Split', 'Fracture', 'Haven'];
      let agent_values = ["Astra", "Breach", "Brimstone", "Chamber", "Cypher", "Jett", "KAY/O", "Killjoy", "Neon", "Omen", "Phoenix", "Raze", "Reyna", "Sage", "Skye", "Sova", "Viper", "Yoru"];
      let mode_values = ['competitive', 'unrated', 'custom'];

      let map_filter = filterthyfilters(map_values, map);
      let agent_filter = filterthyfilters(agent_values, agent);
      let mode_filter = filterthyfilters(mode_values, mode);
      let act_filter = filteracts(episode, act);

      let retobj = {};

      for (const [key, value] of Object.entries(data)) {
        if (map_filter.includes(value[1]) && agent_filter.includes(value[3][2]) && mode_filter.includes(value[2]) && act_filter.includes(value[4])) {
          retobj['matchids__' + key] = value
        }
      }
      return retobj;
    }

    function agentImg(val) {
      if (val === "KAY/O") {
        val = "KAYO"
      }
      return `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val}.png`
    }

    // Overview Functions
    const overviewChart = computed(() => {
      return {
        labels: histChartLength.value,
        datasets: [
          {
            type: 'line',
            label: 'Kills',
            fill: false,
            data: histChartKills.value,
            lineTension: 0.35,
            pointStyle: 'rect',
            pointRadius: 5,
            pointHoverRadius: 8,
            borderColor: [
              'rgba(233, 69, 96, 1)',
            ],
            borderWidth: 2,
          },
          {
            type: 'line',
            label: 'Deaths',
            fill: true,
            data: histChartDeaths.value,
            lineTension: 0.35,
            pointRadius: 5,
            pointHoverRadius: 8,
            backgroundColor: [
                'rgba(34, 48, 60, 0.6)'
            ],
            borderColor: [
              'rgba(34, 48, 60, 1)',
            ],
            borderWidth: 2,
            //borderDash: [10,5],
          },
        ]
      }
    });

    const overviewChartOption = computed(() => {
      return {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        maintainAspectRatio: false,
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              display: true,
            },
            title: {
              display: true,
              text: 'Average'
            },
          },
          x: {
            reverse: true,
            grid: {
              display: false,
            },
            drawBorder: false,
            ticks: {
              display: false,
            },
            title: {
              display: true,
              text: 'Matches',
              font: {
                family: 'Poppins',
                size: 12
              }
            },
          },
        },
      }
    });

    // session innit
    sessionInit();

    // Whatever we need to enable for use outside the script and within the DOM
    return {
      matches,
      acts,
      episodes,
      actmap,
      totalMatches,
      currMatchNum,
      matchwiseStat,
      matchRefresh,
      fetchingComplete,
      detailDiv,
      showDetail,
      removeDetail,
      isHidden,
      episodeChange,
      onClickFilter,
      clearFilter,
      mapSelect,
      agentSelect,
      gameModeSelect,
      episodeSelect,
      actSelect,
      parentList,
      iconLoad,
      notesMatch,
      notesData,
      checkboxArr,
      compareMatches,
      compareData,
      checkboxClick,
      clearCheckboxSelection,
      showNotes,
      isLoading,
      modalLoading,
      firstTime,
      toast,
      agentImg,
      overallMatchesCount,
      isPremium,
      easyToggle,
      toggleState,
      toggleThyCharts,
      overviewChart,
      overviewChartOption,
      histWins,
      histLosses,
      histCompetitive,
      histUnrated,
      histCustom,
    }
  }


}
</script>