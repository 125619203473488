<template>
  <Navbar/>
  <div class="flex flex-col md:flex-row items-center mb-40">
    <div class="w-4/5 flex flex-col items-center">
      <div class="carousel w-full">
        <div id="item1" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Dashboard.png">
            </div>
            <p class="flex-none text-lg">All-in-one <span class="text-secondary capitalize">dashboard</span></p>
          </div>
        </div>
        <div id="item2" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <video autoplay loop muted playsinline src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Killmap_full.webm"></video>
            </div>
            <p class="flex-none text-lg">Analyse <span class="text-secondary capitalize">frags</span> in detail</p>
          </div>
        </div>
        <div id="item3" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Matchwise.png">
            </div>
            <p class="flex-none text-lg">In-depth <span class="text-secondary capitalize">match report</span></p>
          </div>
        </div>
        <div id="item4" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <video autoplay loop muted playsinline src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/2d_demo_viewer.webm"></video>
            </div>
            <p class="flex-none text-lg">2D <span class="text-secondary capitalize">Demo Viewer</span></p>
          </div>
        </div>
        <div id="item5" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <video autoplay loop muted playsinline src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Mapwise_deathmap_full.webm"></video>
            </div>
            <p class="flex-none text-lg"><span class="text-secondary capitalize">Heatmaps</span> for detailed analysis</p>
          </div>
        </div>
        <div id="item6" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Economy.png">
            </div>
            <p class="flex-none text-lg">Track your <span class="text-secondary capitalize">economy</span></p>
          </div>
        </div>
        <div id="item7" class="carousel-item w-full">
          <div class="flex items-center justify-center px-4">
            <div class="product__screen">
              <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/Mapwise.png">
            </div>
            <p class="flex-none text-lg">Performance across <span class="text-secondary capitalize">maps</span></p>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full py-2 gap-4">
        <a href="#item1" class="btn btn-xs"></a>
        <a href="#item2" class="btn btn-xs"></a>
        <a href="#item3" class="btn btn-xs"></a>
        <a href="#item4" class="btn btn-xs"></a>
        <a href="#item5" class="btn btn-xs"></a>
        <a href="#item6" class="btn btn-xs"></a>
        <a href="#item7" class="btn btn-xs"></a>
      </div>
    </div>
    <div class="flex flex-col w-1/5 items-center md:border-l border-gray-900">
      <p class="text-lg text-start mb-4 leading-loose tracking-wider text-secondary font-black text-2xl uppercase">Valorant</p>
      <div class="form-control">
        <div class="btn text-2xs md:text-md" style="background-color: #d13639" @click="redirectRSO">
          <img class="h-3/5 md:h-4/5"
               src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/riot-fist-white-red-rgb.png">
          <span class="hidden md:block">Sign up with Riot</span> <span class="block md:hidden">Riot Signup</span>
        </div>
        <div class="mt-3 mb-3 flex flex-wrap">
          <p class="text-start text-2xs text-gray-600">
            By signing up, you accept our
            <router-link to="/tos" class="text-info">terms of service</router-link>
            , <br>
            our
            <router-link to="/privacy-policy" class="text-info">privacy policy</router-link>
            , and our mailing list<br>
            (which you can unsubscribe later)
          </p>
        </div>
        <div class="mb-3">
          <p class="text-sm">Already have account?
            <router-link to="/login" class="text-secondary">Login</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer/>

</template>

<script>
import axios from 'axios'
import Navbar from "@/components/Navbar";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {eventLog, sessionInit} from "@/log_api";
import Footer from "@/components/Footer";

export default {
  name: 'Signup',
  components: {Footer, Navbar},
  setup() {
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const errors = ref([]);
    const router = useRouter();
    const route = useRoute();

    function submitForm() {
      const formData = {
        username: username.value,
        password: password.value,
        email: email.value
      };

      axios
          .post("/accounts/users/", formData)
          .then(response => {
            // console.log(response)
            router.push('/account/activation-pending')
          })
          .catch(error => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors.push(`${property}: ${error.response.data[property]}`)
              }
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(JSON.stringify(error.message))
            } else {
              console.log(JSON.stringify(error))
            }
          })
    };

    function redirectRSO() {
      window.location.href = `https://auth.riotgames.com/authorize?redirect_uri=https://swiftskill.gg/riot-login/&client_id=swiftskill&response_type=code&scope=openid+offline_access`
    }

    async function checkCode() {
      let code;
      code = route.query.ref;
      // console.log(typeof(code))
      if (code !== '') {
        await axios.post(
            '/account/in/ref-check/',
            {'referral_code': code}
        ).then(
            response => {
              if (response.data === 'Valid') {
                localStorage.setItem('ref', code)
              } else {
                alert('Code Invalid')
              }
            }
        )
      }
    }

    onMounted(() => {
      checkCode()
      // session start
      sessionInit();
      // event trigger
      eventLog(
          'page_view',
          {
            'page_name': 'Sign Up',
            'page_details': {}
          },
          window.location.href
      )
    })

    return {
      username,
      email,
      password,
      errors,
      submitForm,
      redirectRSO
    }
  }
}
</script>