<template>
  <div class="flex justify-center">
    <canvas ref="heatmapCnv"></canvas>
    <div v-if="premium" class="flex flex-col justify-center items-center">
      <div class="flex border border-base-300 rounded-box space-x-2 px-4 py-2 mt-4 mb-4">
        <div class="form-control"><label class="label">
          <span class="label-text text-gray-400">Start (seconds)</span>
        </label>
          <input
              @change="timeChange('start')"
              class="input"
              ref="startVal"
              type="number"
              min="0" max="180"
              value="0"
              step="10"
          ></div>
        <div class="form-control"><label class="label">
          <span class="label-text text-gray-400">End (seconds)</span>
        </label>
          <input
              @change="timeChange('end')"
              class="input"
              ref="endVal"
              type="number"
              min="0"
              max="180"
              value="0"
              step="10"
          ></div>
      </div>
      <div class="flex items-center border border-base-300 rounded-box space-x-2 px-6 py-2">
        <button ref="attackerButton" class="btn btn-accent rounded-box text-2xs" @click="switchSide('attacker')">
          Attacker
        </button>
        <div class="divider divider-vertical"><i class="text-2xl uil uil-exchange"></i></div>
        <button ref="defenderButton" class="btn btn-accent rounded-box text-2xs" @click="switchSide('defender')">
          Defender
        </button>
      </div>
      <div class="flex text-sm mt-3 mb-3 badge badge-info">
        {{ startTime }} to {{ endTime }}
      </div>
      <div class="flex space-x-2 mt-3">
        <button @click="timeFilter" class="btn btn-info rounded-box">Apply</button>
        <button class="btn btn-info rounded-box" @click="resetState">Reset</button>
      </div>
    </div>
    <div class="mt-3" v-else>
      <p>Use Swift Fuel to unlock all filters such as a Attacker Side sorting</p>
      <button class="btn btn-warning mt-3" @click="$emit('fueltest')">Fuel Up</button>
    </div>
  </div>
</template>

<script>

import {onUpdated, ref} from "vue";
import axios from "axios";

export default {
  name: 'MapwiseKD',
  props: {
    chartType: String,
    pointData: Object,
    mapname: String,
    isPremium: Boolean,
  },
  setup(props) {
    const heatmapCnv = ref(null);
    let hcanvas = null;
    let hCtx = null;
    const attackerButton = ref(null);
    const defenderButton = ref(null);

    const currTimeFilter = ref(null);

    const startVal = ref(null);
    const endVal = ref(null);
    const startTime = ref('0:00');
    const endTime = ref('0:00');

    const currSide = ref('both');

    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const csize = 600;
    const prad = 6;

    // Premium vars
    const premium = ref(false);

    function initial() {
      try {
        const heatmapcanvas = heatmapCnv.value;
        const heatmapCtx = heatmapcanvas.getContext('2d');
        hcanvas = heatmapCnv.value;
        hCtx = heatmapcanvas.getContext('2d');

        heatmapcanvas.width = csize;
        heatmapcanvas.height = csize;
        heatmapcanvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${props.mapname}.png)`;
        heatmapcanvas.style.backgroundRepeat = "no-repeat";
        heatmapcanvas.style.backgroundSize = "100%";

        default_chart(heatmapCtx, heatmapcanvas, props.pointData, currTimeFilter.value, 'both');
      } catch (err) {
        console.log(err)
      }
    }

    function default_chart(ctx, canvas, data, timeFilter, filter) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const sideColorMap = {
        'attacker': '#E94560',
        'defender': '#00FF00'
      };
      if (timeFilter === null) {
        if (filter === 'both') {
          for (let obj of data) {
            drawOnCanvas(ctx, obj[0], obj[1], sideColorMap[obj[3]]);
          }
        } else {
          for (let obj of data) {
            if (filter === obj[3]) {
              drawOnCanvas(ctx, obj[0], obj[1], sideColorMap[obj[3]]);
            }
          }
        }
      } else {
        if (filter === 'both') {
          for (let obj of data) {
            if ((timeFilter[0] <= obj[2]) && (obj[2] <= timeFilter[1])) {
              drawOnCanvas(ctx, obj[0], obj[1], sideColorMap[obj[3]]);
            }
          }
        } else {
          for (let obj of data) {
            if (filter === obj[3]) {
              if ((timeFilter[0] <= obj[2]) && (obj[2] <= timeFilter[1])) {
                drawOnCanvas(ctx, obj[0], obj[1], sideColorMap[obj[3]]);
              }
            }
          }
        }
      }
    }

    function drawOnCanvas(ctx, xCord, yCord, color) {
      const point = new Path2D();
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;

      point.arc(x, csize - y, prad, 0, 2 * Math.PI);

      ctx.fillStyle = color;
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowColor = color;
      ctx.fill(point);
    }

    function switchSide(value) {
      if (value === 'attacker') {
        defenderButton.value.classList.remove('btn-primary');
        defenderButton.value.classList.add('btn-accent');
        attackerButton.value.classList.remove('btn-accent');
        attackerButton.value.classList.add('btn-primary');
      } else {
        attackerButton.value.classList.remove('btn-primary');
        attackerButton.value.classList.add('btn-accent');
        defenderButton.value.classList.remove('btn-accent');
        defenderButton.value.classList.add('btn-primary');
      }
      currSide.value = value;
      default_chart(hCtx, hcanvas, props.pointData, currTimeFilter.value, currSide.value);
    }

    function resetState() {
      currSide.value = 'both';
      attackerButton.value.classList.remove('btn-primary');
      defenderButton.value.classList.remove('btn-primary');
      attackerButton.value.classList.add('btn-accent');
      defenderButton.value.classList.add('btn-accent');
      hCtx.clearRect(0, 0, hcanvas.width, hcanvas.height);
      currTimeFilter.value = null;
      startVal.value.value = "0";
      endVal.value.value = "0";
      startTime.value = "0:00";
      endTime.value = "0:00";
      default_chart(hCtx, hcanvas, props.pointData, currTimeFilter.value, 'both');
    }

    function timeFilter() {
      currTimeFilter.value = [
        startVal.value.value * 1000, endVal.value.value * 1000
      ]
      default_chart(hCtx, hcanvas, props.pointData, currTimeFilter.value, currSide.value);
    }

    function timeChange(type) {
      if (type === 'start') {
        if (startVal.value.value < 60) {
          startTime.value = `0:${startVal.value.value}`;
        } else if ((60 <= startVal.value.value) && (startVal.value.value < 120)) {
          const tsplit = startVal.value.value - 60;
          startTime.value = `1:${tsplit}`;
        } else if ((120 <= startVal.value.value) && (startVal.value.value <= 180)) {
          const tsplit = startVal.value.value - 120;
          startTime.value = `2:${tsplit}`;
        }
      } else {
        if (endVal.value.value < 60) {
          endTime.value = `0:${endVal.value.value}`;
        } else if ((60 <= endVal.value.value) && (endVal.value.value < 120)) {
          const tsplit = endVal.value.value - 60;
          endTime.value = `1:${tsplit}`;
        } else if ((120 <= endVal.value.value) && (endVal.value.value <= 180)) {
          const tsplit = endVal.value.value - 120;
          endTime.value = `2:${tsplit}`;
        }
      }
    }

    async function premiumCheck() {
      premium.value = props.isPremium;
    }


    onUpdated(() => {
      initial();
      premiumCheck();
    })

    return {
      heatmapCnv,
      attackerButton,
      defenderButton,
      currSide,
      switchSide,
      resetState,
      startVal,
      startTime,
      endVal,
      endTime,
      timeFilter,
      timeChange,
      premium,
    }
  }
}
</script>