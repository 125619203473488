<template>
  <div v-if="premApply()" class="flex flex-row items-center justify-between" id="heatmapParent">
    <div class="flex flex-col items-center">
      <canvas ref="heatmapcnv" id="heatmap"></canvas>
      <p class="text-xs text-gray-600 mb-3"> Green = Defender and Red = Attacker</p>
      <div class="flex space-x-4 items-center">
        <div class="flex space-x-4 items-center">
          <button ref="attackerButton" class="btn btn-info rounded-box" @click="switchSide('Red')">Attacker</button>
          <div class="divider divider-vertical"><i class="text-2xl uil uil-exchange"></i></div>
          <button ref="defenderButton" class="btn btn-info rounded-box" @click="switchSide('Blue')">Defender</button>
        </div>
        <div class="divider divider-vertical"></div>
        <button
            class="px-3 py-2 border-2 hover:bg-base-300 rounded-box"
            @click="resetState">
          Reset
        </button>
        <button
            class="px-3 py-2 border-2 hover:bg-base-300 rounded-box"
            @click="changeBG">
          Toggle Callout
        </button>
      </div>
    </div>
    <div class="bg-base-100 rounded-box px-4 py-4">
      <p v-if="pointData['chartType'] === 'kill'" class="text-sm text-gray-400">Shows where YOU got the kill from. <br>Click on a point to view more details.</p>
      <p v-else class="text-sm text-gray-400">Shows where YOU died. <br>Click on a point to view more details. </p>
      <div ref="summaryP" class="flex flex-col"></div>
    </div>
  </div>
  <div v-else class="flex flex-row items-center justify-between">
    <div class="card artboard">
      <div class="badge badge-lg badge-warning px-10 py-32">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"/>
        </svg>
        <span class="px-4">This is a premium only feature</span>
        <router-link to="/pricing" class="btn btn-info">Go Pro</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {onUpdated, ref} from "vue";
import store from "@/store";
import {eventLog} from "@/log_api";

export default {
  name: 'Heatmap',
  props: {
    pointData: Object
  },
  setup(props, { emit }) {
    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const csize = 680;//540;
    const prad = 7;//5;
    const kdetails = ref([]);
    const heatmapcnv = ref(null);
    let hcanvas = null;
    let hCtx = null;

    const summaryP = ref(null);

    let callouts = false;
    const leechesCount = ref(0);
    const leechesHoverCount = ref(0);
    let trigger = false;
    const getClicked = ref(false);
    let pointsClicked = 0;

    // Side vars
    const teams = ref([]);
    const firstSide = ref('');
    const secondSide = ref('');
    const firstSideCount = ref(0);
    const seconSideCount = ref(0);

    // Slider vars
    const timeChanger = ref(null);
    const currTimeValue = ref('0');

    const attackerButton = ref(null);
    const defenderButton = ref(null);
    const currSide = ref('both');


    const childEmit = () => {
      emit('childEmit', firstSide.value, secondSide.value, firstSideCount.value, seconSideCount.value);
    }

    function initial() {
      try {
        const heatmapcanvas = heatmapcnv.value;
        const heatmapCtx = heatmapcanvas.getContext('2d');
        hcanvas = heatmapcnv.value;
        hCtx = heatmapcanvas.getContext('2d');

        heatmapcanvas.width = csize;
        heatmapcanvas.height = csize;
        heatmapcanvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${props.pointData['mapname']}.png)`;
        heatmapcanvas.style.backgroundRepeat = "no-repeat";
        heatmapcanvas.style.backgroundSize = "100%";

        teams.value = props.pointData['teams'];

        defaultChart(heatmapCtx, heatmapcanvas, null, currSide.value);
        addleeches(kdetails, heatmapCtx, heatmapcanvas);

        childEmit();

        // let kdetails_mod = [];
        // for (const val of kdetails.value) {
        //   let dummy = {}
        //   for (const [k, v] of Object.entries(val)) {
        //     dummy[k] = v;
        //   }
        //   kdetails_mod.push(dummy)
        // }
        // addleechesHover(kdetails, kdetails_mod, heatmapCtx, heatmapcanvas);
      } catch (err) {
        console.log(err)
      }
    }

    function switchSide(value) {
      if (value === 'Red') {
        defenderButton.value.classList.remove('btn-base-300');
        defenderButton.value.classList.add('btn-info');
        attackerButton.value.classList.remove('btn-info');
        attackerButton.value.classList.add('btn-base-300');
      } else {
        attackerButton.value.classList.remove('btn-base-300');
        attackerButton.value.classList.add('btn-info');
        defenderButton.value.classList.remove('btn-info');
        defenderButton.value.classList.add('btn-base-300');
      }
      currSide.value = value;
      defaultChart(hCtx, hcanvas, null, currSide.value);
    }

    function premApply() {
      return props.pointData['chartType'] === 'kill' ? ref(true) : props.pointData['premiumSub'];
    }

    function timeFilter() {
      currTimeValue.value = timeChanger.value.value;
    }

    function applyFilter() {
      const heatmapcanvas = heatmapcnv.value;
      const heatmapCtx = heatmapcanvas.getContext('2d');
      defaultChart(heatmapCtx, heatmapcanvas, currTimeValue.value, currSide.value);
    }

    function resetState() {
      const heatmapcanvas = heatmapcnv.value;
      const heatmapCtx = heatmapcanvas.getContext('2d');

      summaryBox(
          'delete',
          {}
      );
      currSide.value = 'both';
      attackerButton.value.classList.remove('btn-base-300');
      defenderButton.value.classList.remove('btn-base-300');
      attackerButton.value.classList.add('btn-info');
      defenderButton.value.classList.add('btn-info');
      defaultChart(heatmapCtx, heatmapcanvas, null, currSide.value);
      getClicked.value = false;
      pointsClicked = 0;
    }

    function weaponImg(val) {
      return val.includes("Ultimate") || val.includes("Ability")
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agent-abilities/${val}`
          :
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}`
    }

    function inRange(ex, ey, x, y) {
      return Math.hypot(ex - x, ey - y)
    }

    function processtime(timeval) {
      let timer
      let time = Math.round(timeval)
      if (time < 60) {
        if (time < 10) {
          timer = '0:0' + String(time)
        } else {
          timer = '0:' + String(time)
        }
      } else if (time === 60) {
        timer = '1:00'
      } else if (time > 60 && time < 120) {
        if (time - 60 < 10) {
          timer = '1:0' + String(time - 60)
        } else {
          timer = '1:' + String(time - 60)
        }
      } else if (time === 120) {
        timer = '2:00'
      } else {
        if (time - 120 < 10) {
          timer = '2:0' + String(time - 120)
        } else {
          timer = '2:' + String(time - 120)
        }
      }
      return timer
    }

    function summaryBox(flag, obj) {
      let summaryParent = summaryP.value;
      if (flag === 'create') {
        getClicked.value = true;
        let docP = document.createElement('div');
        docP.classList.add('summaryElements', 'flex', 'flex-row', 'items-center', 'py-3');

        let docRound = document.createElement('div');
        docRound.classList.add('summaryElements', 'text-secondary', 'badge', 'badge-accent', 'text-xs','py-4');
        docRound.textContent = obj['roundNum'];
        docP.append(docRound)

        let docTime = document.createElement('div');
        docTime.classList.add('summaryElements', 'py-4', 'badge', 'badge-info');
        docTime.setAttribute('title', 'Round Timer');
        docTime.textContent = obj['time'];
        docP.append(docTime)

        summaryParent.append(docP);

        let docImg = document.createElement('img');
        docImg.classList.add('summaryElements', 'py-3');
        docImg.style.maxWidth = "96px";
        docImg.style.maxHeight = "256px";
        docImg.style.width = "50%";
        docImg.style.height = "50%";
        docImg.src = obj['weaponName'];
        summaryParent.append(docImg);
      } else {
        while (summaryParent.lastChild) {
          summaryParent.removeChild(summaryParent.lastChild);
        }
      }
    }

    function addleeches(arr, ctx, canvas) {
      if (leechesCount.value < 1) {
        canvas.addEventListener("click", function (event) {
          if (getClicked.value === true) {
            pointsClicked += 1;
          }
          arr.value.forEach(function (obj) {
            let x = obj['x'] / 1024 * csize;
            let y = csize - obj['y'] / 1024 * csize;
            if (getClicked.value === false) {
              if (inRange(event.offsetX, event.offsetY, x, y) <= prad) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                let vertices = [];
                const chartType = props.pointData['chartType'];
                summaryBox(
                    'create',
                    {
                      time: processtime(obj['time']),
                      roundNum: `Round ${obj['roundnum']}`,
                      weaponName: weaponImg(obj['weapon_id'])
                    }
                );

                if (chartType === 'kill') {
                  drawOnCanvas_agent(ctx, canvas, obj['vloc'][0], obj['vloc'][1], obj['victimagent'], 'victim');
                  drawOnCanvas_agent(ctx, canvas, obj['x'], obj['y'], obj['killeragent'], 'killer');
                  drawLineEnemies(ctx, obj['x'], obj['y'], obj['vloc'][0], obj['vloc'][1], vertices);
                  animateIt(ctx, vertices);
                } else {
                  drawOnCanvas_agent(ctx, canvas, obj['kloc'][0], obj['kloc'][1], obj['killeragent'], 'killer');
                  drawOnCanvas_agent(ctx, canvas, obj['x'], obj['y'], obj['victimagent'], 'victim');
                  drawLineEnemies(ctx, obj['kloc'][0], obj['kloc'][1], obj['x'], obj['y'], vertices);
                  animateIt(ctx, vertices);
                }
                pointsClicked += 1;
                // event trigger
                eventLog(
                    'heatmap_interaction',
                    {
                      'page_name': 'Heatmap',
                      'heatmap_name': chartType,
                      'details': [
                        props.pointData['mapname'],
                        [x, y]
                      ]
                    },
                    window.location.href
                )
              }
            }
          })
          if (pointsClicked > 1) {
            resetState();
          }
        })
      }
      leechesCount.value += 1;
    }

    function addleechesHover(arr, arrmod, ctx, canvas) {
      if (leechesHoverCount.value < 1) {
        canvas.addEventListener("mousemove", function (event) {
          let selected_obj = {};
          for (const obj of arrmod) {
            let x = obj['x'] / 1024 * csize;
            let y = csize - obj['y'] / 1024 * csize;
            if (inRange(event.offsetX, event.offsetY, x, y) <= prad) {
              trigger = true;
              selected_obj = obj;
              break;
            } else {
              trigger = false;
              //state reset relay variable
              resetState();
            }
          }
          if (trigger) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            let vertices = [];
            const chartType = props.pointData['chartType'];
            summaryBox(
                'create',
                {
                  time: processtime(selected_obj['time']),
                  roundNum: `Round ${selected_obj['roundnum']}`,
                  weaponName: weaponImg(selected_obj['weapon_id'])
                }
            );

            if (chartType === 'kill') {
              drawOnCanvas_agent(ctx, canvas, selected_obj['vloc'][0], selected_obj['vloc'][1], selected_obj['victimagent'], 'victim');
              drawOnCanvas_agent(ctx, canvas, selected_obj['x'], selected_obj['y'], selected_obj['killeragent'], 'killer');
              drawLineEnemies(ctx, selected_obj['x'], selected_obj['y'], selected_obj['vloc'][0], selected_obj['vloc'][1], vertices);
              // animateIt(ctx, vertices);
            } else {
              drawOnCanvas_agent(ctx, canvas, selected_obj['kloc'][0], selected_obj['kloc'][1], selected_obj['killeragent'], 'killer');
              drawOnCanvas_agent(ctx, canvas, selected_obj['x'], selected_obj['y'], selected_obj['victimagent'], 'victim');
              drawLineEnemies(ctx, selected_obj['kloc'][0], selected_obj['kloc'][1], selected_obj['x'], selected_obj['y'], vertices);
              // animateIt(ctx, vertices);
            }
          }
        })
      }
      leechesHoverCount.value += 1;
    }

    function defaultChart(ctx, canvas, roundnum, toggle) {
      const sideColorMap = {
        'Red': '#E94560',
        'Blue': '#00FF00'
      };
      let pData = props.pointData['pointData'];
      const breakRound = 12;

      if (teams.value['Red'].includes(store.state.user.uuid)) {
        firstSide.value = 'Red';
        secondSide.value = 'Blue';
      } else {
        firstSide.value = 'Blue';
        secondSide.value = 'Red';
      }
      if (roundnum === null) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        firstSideCount.value = 0;
        seconSideCount.value = 0;
        if (toggle === 'both') {
          for (const [key, value] of Object.entries(pData)) {
            for (let obj of value) {
              if (Number(obj['roundnum']) < breakRound) {
                drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[firstSide.value]);
                firstSideCount.value += 1;
              } else {
                drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[secondSide.value]);
                seconSideCount.value += 1;
              }
              kdetails.value.push(obj);
            }
          }
        } else {
          for (const [key, value] of Object.entries(pData)) {
            for (let obj of value) {
              if (Number(obj['roundnum']) < breakRound) {
                if (firstSide.value === toggle) {
                  drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[firstSide.value]);
                }
              } else {
                if (secondSide.value === toggle) {
                  drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[secondSide.value]);
                }
              }
              kdetails.value.push(obj);
            }
          }
        }
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (const [key, value] of Object.entries(pData)) {
          for (let obj of value) {
            if (Number(obj['roundnum']) <= Number(roundnum)) {
              if (Number(obj['roundnum']) < breakRound) {
                drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[firstSide.value]);
              } else {
                drawOnCanvas(ctx, canvas, obj['x'], obj['y'], sideColorMap[secondSide.value]);
              }
              kdetails.value.push(obj);
            }
          }
        }
      }
    }

    function drawOnCanvas(ctx, canvas, xCord, yCord, color) {
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;
      let point = new Path2D();

      ctx.fillStyle = color;
      point.arc(x, csize - y, prad, 0, 2 * Math.PI);
      //ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.shadowColor = color;
      ctx.fill(point);
    }

    function drawOnCanvas_agent(ctx, canvas, xCord, yCord, agent, kind) {
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;

      const colormap = {
        "killer": "#00FF00",
        "victim": "#DC143C"
      }

      let nImg = new Image();
      nImg.onload = function () {
        let point = new Path2D();
        ctx.fillStyle = colormap[kind];
        point.arc(x - 5, csize - y - 2, 15, 0, 2 * Math.PI);
        //ctx.shadowBlur = 50;
        ctx.shadowColor = colormap[kind];
        ctx.fill(point);
        ctx.drawImage(nImg, x - 15, csize - y - 15, 25, 25);

      }
      nImg.src = cdnurl + `/agents/${agent}.png`;
    }

    function drawLineEnemies(ctx, x1, y1, x2, y2, vertices) {
      ctx.setLineDash([8, 12])
      ctx.beginPath();
      ctx.moveTo(x1 / 1024 * csize, csize - y1 / 1024 * csize);
      ctx.lineTo(x2 / 1024 * csize, csize - y2 / 1024 * csize);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0";
      ctx.stroke();

      vertices.push({
        x: x1 / 1024 * csize,
        y: csize - y1 / 1024 * csize
      });
      vertices.push({
        x: x2 / 1024 * csize,
        y: csize - y2 / 1024 * csize
      });
    }

    function calcWaypoints(vertices) {
      let waypoints = [];
      for (let i = 1; i < vertices.length; i++) {
        let pt0 = vertices[i - 1];
        let pt1 = vertices[i];
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        for (let j = 0; j < 100; j++) {
          let x = pt0.x + dx * j / 100;
          let y = pt0.y + dy * j / 100;
          waypoints.push({
            x: x,
            y: y
          });
        }
      }
      return (waypoints);
    }

    function animateIt(ctx, vertices) {
      function animate() {
        if (t < points.length - 1) {
          requestAnimationFrame(animate);
        }
        ctx.beginPath();
        ctx.moveTo(points[t - 1].x, points[t - 1].y);
        ctx.lineTo(points[t].x, points[t].y);
        ctx.fill();
        ctx.stroke();
        t++;
      }

      let t = 1;
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0";

      let points = calcWaypoints(vertices);
      animate(points);
    }

    function changeBG() {
      const heatmapcanvas = heatmapcnv.value;
      if (callouts === false) {
        callouts = true;
        heatmapcanvas.style.backgroundImage = `url('https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/maps-callouts/${
            props.pointData['mapname']
        }.png')`
      } else {
        callouts = false;
        heatmapcanvas.style.backgroundImage = `url('https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/maps-general/${
            props.pointData['mapname']
        }.png')`
      }
    }

    onUpdated(() => {
      initial()
    });

    return {
      summaryP,
      heatmapcnv,
      resetState,
      changeBG,
      premApply,
      firstSide,
      secondSide,
      firstSideCount,
      seconSideCount,
      timeChanger,
      timeFilter,
      applyFilter,
      currTimeValue,
      attackerButton,
      defenderButton,
      switchSide,
      childEmit,
    }
  }
}
</script>