<template>

</template>


<script>

import {useRoute, useRouter} from "vue-router";

export default {
  name: 'UserSignupReferral',
  setup() {
    const route = useRoute();
    const router = useRouter();

    const referrer = route.params.userunique
    localStorage.setItem('new_user_referrer', String(referrer))
    console.log(referrer)

    router.push('/dashboard')
  }
}
</script>