<template>

</template>

<script>

import {useRoute, useRouter} from "vue-router";
import axios from "axios";
import {useToast} from "vue-toastification";

export default {
  name: 'DiscordRedirect',
  async setup(){
    const route = useRoute();
    const router = useRouter();
    const code = route.query.code;
    const toast = useToast();
    let csrf_token;

    await axios.get(
        '/valorant/csrf-token/'
    ).then(
        response => {
          csrf_token = response.data
        }
    )

    await axios.post(
        '/account/in/discord-redirect/',
        {
          headers: {
            'X-CSRFToken': csrf_token
          },
          accCode: code
        }
    ).then(
        response => {
          if (response.data === 'Success'){
            toast.success('Successfully Connected Discord. You have been refuelled', {
              timeout: 5000
            })
            router.push('/dashboard')
          }
        }
    )
  }
}

</script>