<template>
  <div class="flex flex-row justify-between">
    <div class="px-4 flex flex-col">
      <canvas ref="deathsnapshot"></canvas>
      <label class="label mt-5 mb-1">Select Round</label>
      <div class="flex space-x-2 items-center">
        <button class="btn btn-primary" @click="numChanger('decrement'); getEcon()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </button>
        <input ref="roundChanger" type="number" disabled @change="roundChange" class="input text-center"
               min="0" :max="totalRounds" value="0">
        <button class="btn btn-primary" @click="numChanger('increment'); getEcon()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"/>
          </svg>
        </button>
      </div>
      <div class="py-4 text-info">
        Round {{ roundVal }} <span class="text-white">won by</span> <span
          class="text-secondary">{{ rSnippet['winningteam'] }}</span><br>
        <span class="text-info">Win Reason</span> <span class="text-white">{{ rSnippet['endreason'] }}</span>
      </div>
      <div class="mb-2">
        <table class="table table-compact">
          <tr class="bg-gray-900">
            <th>Weapon</th>
            <th>Armor</th>
          </tr>
          <tr>
            <td class="border-r">{{ currEconWeapon }}</td>
            <td v-if="currEconArmor !== ''">{{ currEconArmor }}</td>
            <td v-else>None</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex flex-col px-4 space-y-4">
      <div class="flex items-center mt-2" v-for="snip in rSnippet['summary']">
        <div class="flex cursor-pointer bg-base-100 hover:bg-gray-900 rounded-box px-2" @click="showKillEvent(snip[1]['deathsnaps'])">
          <div class="px-2 py-2 mt-2 mb-2 mr-4">
            {{ processtime(snip[0]) }}
          </div>
          <div class="flex items-center space-x-4" v-if="snip[1]['eventtype'] === 'kill'">
            <span v-if="agentImg(snip[1]['details']['killer'], 'bg') === 'Enemy'">
              <img class="border-2 border-secondary rounded-box" :src="agentImg(snip[1]['details']['killer'], 'path')"
                   style="width: 35px;">
            </span>
              <span v-else>
              <img class="border-2 border-green-500 rounded-box" :src="agentImg(snip[1]['details']['killer'], 'path')"
                   style="width: 35px;">
            </span>

              <img :src="weaponImg(snip[1]['details']['weapon'])"
                   style="transform: scaleX(-1)" class="h-6">

              <span v-if="agentImg(snip[1]['details']['victim'], 'bg') === 'Enemy'">
              <img class="border-2 border-secondary rounded-box" :src="agentImg(snip[1]['details']['victim'], 'path')"
                   style="width: 35px;">
            </span>
              <span v-else>
              <img class="border-2 border-green-500 rounded-box" :src="agentImg(snip[1]['details']['victim'], 'path')"
                   style="width: 35px;">
            </span>
          </div>
          <div class="flex items-center space-x-4" v-else-if="snip[1]['eventtype'] === 'bombplant'">
            <span v-if="agentImg(snip[1]['details']['planter'], 'bg') === 'Enemy'">
              <img class="border-2 border-secondary rounded-box" :src="agentImg(snip[1]['details']['planter'], 'path')"
                   style="width: 35px;">
            </span>
              <span v-else>
              <img class="border-2 border-green-500 rounded-box" :src="agentImg(snip[1]['details']['planter'], 'path')"
                   style="width: 35px;">
            </span>
              <span class="ml-4 text-sm">Planted Spike on {{ snip[1]['details']['site'] }} Site</span>
          </div>
          <div class="flex items-center space-x-4" v-else>
            <span v-if="agentImg(snip[1]['details']['defuser'], 'bg') === 'Enemy'">
              <img class="border-2 border-secondary rounded-box" :src="agentImg(snip[1]['details']['defuser'], 'path')"
                   style="width: 35px;">
            </span>
              <span v-else>
              <img class="border-2 border-green-500 rounded-box" :src="agentImg(snip[1]['details']['defuser'], 'path')"
                   style="width: 35px;">
            </span> <span class="ml-4 text-sm">Defused Spike</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {onUpdated, ref} from "vue";
import {eventLog} from "@/log_api";

export default {
  name: 'RoundSnippet',
  props: {
    econ: Object,
    mapname: String,
    premium: Boolean,
    oldData: Boolean,
    dsData: Object,
    totalRounds: Number,
    snippet: Object
  },
  setup(props) {
    const cdnurl = 'https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets';
    const deathsnapshot = ref(null);
    let ds_canvas = null;
    let ds_ctx = null;
    let rSnippet = ref({});
    let snippet = null;
    let ds_data = null;
    let allkeys = null;
    let mapname = null;
    let econ = null;

    let currEconWeapon = ref(null);
    let currEconArmor = ref(null);

    let roundChanger = ref(null);
    let roundVal = ref('0');

    const csize = 500;//400;

    let currkey = '0';

    function initial() {
      if (props.premium === true) {
        snippet = props.snippet;
        ds_data = props.dsData;
        allkeys = Object.keys(ds_data);
        mapname = props.mapname;
        econ = props.econ;

        ds_canvas = deathsnapshot.value;
        ds_ctx = ds_canvas.getContext('2d');
        ds_canvas.width = csize;
        ds_canvas.height = csize;
        ds_canvas.style.backgroundImage = 'url(' + cdnurl + `/maps-general/${mapname}.png)`;
        ds_canvas.style.backgroundRepeat = "no-repeat";
        ds_canvas.style.backgroundSize = "100%";
      }
      roundChange();
    }

    function processtime(timeval) {
      let timer
      let time = Math.round(timeval / 1000)
      if (time < 60) {
        if (time < 10) {
          timer = '0:0' + String(time)
        } else {
          timer = '0:' + String(time)
        }
      } else if (time === 60) {
        timer = '1:00'
      } else if (time > 60 && time < 120) {
        if (time - 60 < 10) {
          timer = '1:0' + String(time - 60)
        } else {
          timer = '1:' + String(time - 60)
        }
      } else if (time === 120) {
        timer = '2:00'
      } else {
        if (time - 120 < 10) {
          timer = '2:0' + String(time - 120)
        } else {
          timer = '2:' + String(time - 120)
        }
      }
      return timer
    }

    function agentImg(val, flag) {
      if (flag === 'path') {
        if (val === "Enemy KAY/O") {
          val = "Enemy KAYO"
        } else if (val === "KAY/O") {
          val = "KAYO"
        }
      }
      return flag === 'path'
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agents/${val.includes("Enemy")
              ?
              val.split(' ')[1]
              :
              val}.png`
          :
          val.includes("Enemy") ? "Enemy" : "Team"
    }

    function weaponImg(val) {
      return val.includes("Ultimate") || val.includes("Ability")
          ?
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/agent-abilities/${val}`
          :
          `https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/weapons/${val}`
    }

    function showKillEvent(deathsnaps) {
      if (deathsnaps.length > 0) {
        individualSnap(deathsnaps);
      } else {
        // Add code for unsupported event
        ds_ctx.clearRect(0, 0, ds_canvas.width, ds_canvas.height);
      }
    }

    function numChanger(flag) {
      if (flag === 'increment') {
        if (roundChanger.value.value < props.totalRounds) {
          roundChanger.value.value++;
          roundVal.value++;
        }
      } else {
        if (roundChanger.value.value > 0) {
          roundChanger.value.value--;
          roundVal.value--;
        }
      }
    }

    function roundChange() {
      roundVal.value = roundChanger.value.value;
      rSnippet.value = snippet[roundChanger.value.value];
      setkey(roundChanger.value.value);
      show_deathsnap();
      currEconWeapon.value = econ[Number(roundChanger.value.value) + 1]['weapon'];
      currEconArmor.value = econ[Number(roundChanger.value.value) + 1]['armor'];
    }

    function setkey(key) {
      currkey = key;
    }

    function individualSnap(deathsnapData) {
      default_dschart(ds_ctx, ds_canvas, deathsnapData, "on", "on");
    }

    function show_deathsnap() {
      if (allkeys.includes(currkey) === true) {
        default_dschart(ds_ctx, ds_canvas, ds_data[currkey], "on", "on");
      } else {
        // Add code for no death this round
        ds_ctx.clearRect(0, 0, ds_canvas.width, ds_canvas.height);
      }
    }

    function default_dschart(ctx, canvas, data, enemyval, teamval) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let vertices = [];

      let killerx = null;
      let killery = null;
      let victimx = null;
      let victimy = null;

      for (let element of data) {
        let x = element[0];
        let y = element[1];
        let agent = element[3];
        let kind = element[4];

        if (kind === 'Killer') {
          killerx = x;
          killery = y;
        } else if (kind === 'Victim') {
          victimx = x;
          victimy = y;
        }
        if (kind === 'Teammate') {
          if (teamval === "on") {
            drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
          }
        } else if (kind === 'Enemy') {
          if (enemyval === "on") {
            drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
          }
        } else {
          drawOnCanvas_ds(ctx, canvas, x, y, agent, kind)
        }
      }
      drawLineEnemies_ds(ctx, canvas, killerx, killery, victimx, victimy, vertices)
      animateIt_ds(ctx, canvas, vertices);
    }

    function drawLineEnemies_ds(ctx, canvas, x1, y1, x2, y2, vertices) {
      x1 = x1 / 1024 * csize;
      y1 = y1 / 1024 * csize;
      x2 = x2 / 1024 * csize;
      y2 = y2 / 1024 * csize;

      ctx.beginPath();
      ctx.setLineDash([8, 12]);
      ctx.moveTo(x1, csize - y1);
      ctx.lineTo(x2, csize - y2);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#FFFAF0"
      ctx.stroke();

      //var vertices = [];
      vertices.push({
        x: x1,
        y: csize - y1
      });
      vertices.push({
        x: x2,
        y: csize - y2
      });
    }

    function animateIt_ds(ctx, canvas, vertices) {
      function animate() {
        if (t < points.length - 1) {
          requestAnimationFrame(animate);
        }
        ctx.beginPath();
        ctx.moveTo(points[t - 1].x, points[t - 1].y);
        ctx.lineTo(points[t].x, points[t].y);
        ctx.stroke();
        t++;
      }

      let t = 1;
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFAF0";
      let points = calcWaypoints_ds(vertices);
      animate(points);
    }

    function calcWaypoints_ds(vertices) {
      let waypoints = [];
      for (let i = 1; i < vertices.length; i++) {
        let pt0 = vertices[i - 1];
        let pt1 = vertices[i];
        let dx = pt1.x - pt0.x;
        let dy = pt1.y - pt0.y;
        for (let j = 0; j < 100; j++) {
          let x = pt0.x + dx * j / 100;
          let y = pt0.y + dy * j / 100;
          waypoints.push({
            x: x,
            y: y
          });
        }
      }
      return (waypoints);
    }

    function drawOnCanvas_ds(ctx, canvas, xCord, yCord, agent, kind) {
      const colormap = {
        "Killer": "#DC143C",
        "Victim": "#00FF00",
        "Teammate": "#00FF00",
        "Enemy": "#DC143C"
      }
      let x = xCord / 1024 * csize;
      let y = yCord / 1024 * csize;
      let nImg = new Image();
      nImg.onload = function () {
        let point = new Path2D();
        ctx.fillStyle = colormap[kind];
        point.arc(x, csize - y, 15, 0, 2 * Math.PI);
        //ctx.shadowBlur = 30;
        ctx.shadowColor = colormap[kind];
        ctx.fill(point);
        ctx.drawImage(nImg, x - 12, csize - y - 12, 25, 25);
      }
      nImg.src = cdnurl + `/agents/${agent}.png`;
    }

    function getEcon() {
      currEconWeapon.value = econ[Number(roundChanger.value.value) + 1]['weapon'];
      currEconArmor.value = econ[Number(roundChanger.value.value) + 1]['armor'];
    }

    onUpdated(() => {
      initial();
    })

    return {
      deathsnapshot,
      rSnippet,
      processtime,
      roundChanger,
      roundChange,
      roundVal,
      weaponImg,
      agentImg,
      showKillEvent,
      numChanger,
      getEcon,
      currEconWeapon,
      currEconArmor,
    }
  }
}
</script>