<template>
  <Navbar />
  <div class="flex flex-col items-center">
    <ul class="steps steps-vertical md:steps-horizontal mt-5 mb-5">
      <li class="step step-primary">Sign Up</li>
      <li class="step">Connect Riot Account</li>
      <li class="step">Let's Go</li>
    </ul>

    <p class="text-secondary mt-5 text-lg">
      Your account has been activated. Please <router-link to="/login" class="text-primary-content">Login</router-link> to continue
    </p>
  </div>
</template>

<script>
import axios from "axios";
import { useRoute } from 'vue-router'
import {onMounted, ref} from "vue";
import Navbar from "@/components/Navbar";
import {eventLog, sessionInit} from "@/log_api";

export default {
  name: 'AccountActivate',
  components: {Navbar},
  setup() {
    const route = useRoute();
    let token = route.params.token;
    let uid = route.params.uid;
    let csrf_token;
    async function checkAcc(){
      await axios.post(
          `/account/in/activate/${uid}/${token}`,
      ).then(
          response => {
            // alert(response)
          }
      )
    }

    // session start
    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Account Activate',
          'page_details': {}
        },
        window.location.href
    )

    onMounted(() => {
      checkAcc()
    })
  },


}
</script>