<template>

</template>

<script>

import {onMounted} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import {eventLog, sessionInit} from "@/log_api";

export default {
  name: 'PaymentFail',
  setup() {
    const router = useRouter();
    onMounted(async () => {
      await axios.get(
          '/account/in/failed'
      )
      await router.push('/pricing')
    })
    // session start
    sessionInit();
    // event trigger
    eventLog(
        'page_view',
        {
          'page_name': 'Payment Fail',
          'page_details': {}
        },
        window.location.href
    )
  }
}
</script>