<template>
  <div class="flex flex-col bg-base-100 border-r border-gray-900 justify-between">
    <div>
      <div class="py-4 justify-between space-y-12 md:space-y-64 flex flex-col artboard md:w-56">
        <ul class="menu">
          <li class="flex items-center mb-4">
            <router-link to="/" class="hover:bg-transparent">
              <img src="https://brovocado-bucket.s3.eu-west-2.amazonaws.com/assets/generic/logo+white.webp" alt="SwiftSkill">
            </router-link>
          </li>
          <li class="md:bordered" v-for="menuItem in menu">
            <router-link :to=menuItem.href class="nav-link tracking-wide leading-6 hover:bg-base-300"><i class="mr-2" :class="menuItem.icon"></i><span>{{
                menuItem.title
              }}</span></router-link>
          </li>
        </ul>
        <ul class="menu">
<!--          <li>-->
<!--            <router-link to="/refer-a-friend" class="nav-link hover:bg-base-300"><i class="uil uil-envelope-add mr-2"></i> Refer a Friend</router-link>-->
<!--          </li>-->
          <li>
            <router-link to="/account-settings" class="nav-link hover:bg-red-700"><i class="uil uil-user mr-2"></i> Account Settings</router-link>
          </li>
          <li>
            <a @click="logout" class="nav-link hover:bg-red-700"><i class="uil uil-signout mr-2"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import {useRouter} from "vue-router";
import axios from "axios";
import {useStore} from "vuex";

export default {
  name: 'Sidebar',
  props: {
    'menu': Object,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    function logout(){
      axios
          .post('accounts/token/logout')
          .then(response => {
            axios.defaults.headers.common['Authorization'] = ''

            localStorage.removeItem('username')
            localStorage.removeItem('userid')
            localStorage.removeItem('token')

            store.commit('removeToken')
            router.push('/logout')
          })
          .catch(error => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data))
            } else if (error.message) {
              console.log(JSON.stringify(error.message))
            } else {
              console.log(JSON.stringify(error))
            }
          })
    }

    return {
      logout
    }
  }
}
</script>